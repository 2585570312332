import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scLotteryRequests';
import React, { useContext } from 'react';
import { sendAndSignTransactions } from './useSendAndSign';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';


export const useExtractWinningNumbers = () => {
  const { account } = useGetAccountInfo();
  const displayInfo = {
    processingMessage: 'Processing create first lottery transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'First lottery created successfully'
  };

  const extractWinningNumbers = async () => {
    const contract = await getSmartContractObj();
    const interaction = contract.methodsExplicit.extractWinningNumbers();
    const { sessionId, error } = await sendAndSignTransactions(
      [
        interaction
          .withNonce(account.nonce)
          .withGasLimit(50_000_000)
          .withChainID(network.chainId)
          .buildTransaction()
      ],

      displayInfo,
    );

    // setSessionId(sessionId);

    return sessionId;
  };

  // console.log(enableContract);
  return extractWinningNumbers;
};
