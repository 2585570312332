import React from 'react';
import { ContractDetails } from './ContractDetails';
import { TransactionsCard } from './TransactionsCard';
import { LotteryDetails } from './LotteryDetails';
import { Layout } from 'components/Layout/Layout';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';
import * as config from '../../config';

export const AdminLottery = () => {
  const { address } = useGetAccount();
  if(address.toString() === config.adminAddress){
    return (
      <>
        <Layout >
          <div className='container'>
            <div className='row'>
              <div className='col-12 text-center'>
                <h1 className='mt-5 mb-5'>
                  Lottery admin panel
                </h1>
              </div>

              <div className='col-lg-6 col-sm-12'>
                <ContractDetails />
              </div>

              <div className='col-lg-6 col-sm-12'>
                <LotteryDetails />
              </div>

              <div className='col-12 mt-3'>
                <TransactionsCard />
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }else{
    return (
      <Layout>
        <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>Page visible to admin only</p>
      </Layout>
    );
  }
};