// store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import auctionInfoReducer from './slices/auctionInfo';

const store = configureStore({
  reducer: {
    auctionInfo: auctionInfoReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;