// export const contractAddress =
//   'erd1qqqqqqqqqqqqqpgqcr6qqpanqzlkav5qhfn34a2gav8uwuvd0ytsvxk0re';

// export const contractAddress = 'erd1qqqqqqqqqqqqqpgqh88cdsxfe6s70x94ce74wuc0c2qs9l5fuxgsua6xkg';
export const contractAddress = 'erd1qqqqqqqqqqqqqpgqd0e8e8pmk7953f5zznzjm7fa284au9efesgqr50pf8';

// export const lotteryContractAddress = 'erd1qqqqqqqqqqqqqpgq3caltsdaasvc374knj5q40fza8afmzzpuxgspgjnkr';
export const lotteryContractAddress = 'erd1qqqqqqqqqqqqqpgqz33dlmjlk8y77z8l82dhkegfdfkf43lcesgqa4qwhc';

export const mysteryKeysContractAddress = 'erd1qqqqqqqqqqqqqpgqxxpu3cefjs8zu0ppl2j6fxdtlfdhepxuwmfs8gplu2';
export const mysteryPrizesContractAddress = 'erd1qqqqqqqqqqqqqpgqc6wksulf3upswhptn59c0vnsaq8rgna7wmfsgq3phy';

export const stakingStonesContractAddress = 'erd1qqqqqqqqqqqqqpgqp7ftn5dc35srdsxpw7nya92eycep5x9kwmfs4rhqlf';

// export const raffleContractAddress = 'erd1qqqqqqqqqqqqqpgq3e3sfhajcj95uh8nq0ka5zjkn3l7nerzwmfsqq5apq';
export const raffleContractAddress = 'erd1qqqqqqqqqqqqqpgqtl7wqgvgklp9z7dfqt6lsckqe0g0fdlcwmfsmacrrs';

export const dAppName = 'xBid';
export const xBidToken = 'XBID-c7e360';
export const mysteryKey = 'KEY-ebe055';

export const stonesSFT = 'STONES-c01a8e';
export const crystalSFT = 'STONES-c01a8e-05';
export const amethystSFT = 'STONES-c01a8e-04';
export const sapphireSFT = 'STONES-c01a8e-03';
export const emeraldSFT = 'STONES-c01a8e-02';
export const rubySFT = 'STONES-c01a8e-01';
export const crystalSFTNonce = '5';
export const amethystSFTNonce = '4';
export const sapphireSFTNonce = '3';
export const emeraldSFTNonce = '2';
export const rubySFTNonce = '1';

// export const adminAddress = 'erd10x2dcvqxvgf8urkaanl7cak4ynhewjt8q5xgl0kssngjnjp40ytssdfd8k';
// export const adminAddress = 'erd1fegge5067awlw94ksycw0gfk8z2zzz2l3rnesjuytz8ampucsnwq5ns2hn';
// export const adminAddress = 'erd1yhg5ejgsp6ng4dj7fuupumujgl5tt80cecqt8vkfg5aq86zvuxgsmkvszv';
export const adminAddress = 'erd17n4gpw7gkjy32fkq2nupqapluqmfhfwyc8kkm6ztjxg9qxdnesgq822ftt';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';


export const network = {
  id: 'mainnet',
  name: 'Mainnet',
  egldLabel: 'EGLD',
  walletAddress: 'https://wallet.multiversx.com',
  apiAddress: 'https://api.multiversx.com',
  gatewayAddress: 'https://gateway.multiversx.com',
  explorerAddress: 'http://explorer.multiversx.com',
  graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
  skipFetchFromServer: true,
  chainId: '1'
};

// export const network = {
//   id: 'mainnet',
//   name: 'Mainnet',
//   egldLabel: 'EGLD',
//   walletAddress: 'https://wallet.multiversx.com',
//   apiAddress: 'https://elrond-api.public.blastapi.io',
//   gatewayAddress: 'https://elrond-mainnet.public.blastapi.io',
//   explorerAddress: 'http://explorer.multiversx.com',
//   graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
//   skipFetchFromServer: true,
//   chainId: '1'
// };

//https://devnet-api.multiversx.com/transactions?receiver=erd1qqqqqqqqqqqqqpgq4fx5p997d2x56y4p3vpnylner4526ekn0ytsycxghw&size=10
//https://devnet-api.multiversx.com/address/erd1qqqqqqqqqqqqqpgq4fx5p997d2x56y4p3vpnylner4526ekn0ytsycxghw/balance

// export const network = {
//   id: 'devnet',
//   name: 'devnet',
//   egldLabel: 'EGLD',
//   walletAddress: 'https://devnet-wallet.multiversx.com',
//   apiAddress: 'https://devnet-api.multiversx.com',
//   gatewayAddress: 'https://devnet-gateway.multiversx.com',
//   explorerAddress: 'http://devnet-explorer.multiversx.com',
//   graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
//   skipFetchFromServer: true,
//   chainId: 'D'
// };

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
