import React, { useState, useEffect, useContext } from 'react';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import { LoadingScreen } from 'components/LoadingScreen';
import { useMysteryAddReward } from 'contexts/hooks/transactions/useMysteryAddReward';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from 'react-bootstrap/Card';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import * as XLSX from 'xlsx';

export const TotalRewards = () => {
  const { getAllTotalRewards, updateTotalRewardByLevel } = useContext(UserContext);

  const [rewards, setRewards] = useState<{ [key: number]: { level: number, rewards: string } }>({});
  const [selectedLevel, setSelectedLevel] = useState<number>(1);
  const [newReward, setNewReward] = useState<string>('');

  const fetchRewards = async () => {
    const rewardsData = await getAllTotalRewards();
    setRewards(rewardsData);
  };

  useEffect(() => {
    fetchRewards();
  }, []);

  const handleUpdateReward = async () => {
    await updateTotalRewardByLevel(selectedLevel, newReward);
    const updatedRewards = await getAllTotalRewards();
    setRewards(updatedRewards);
  };

  return (
    <>
      <Card bg='dark'>
        <Card.Header>
          Total Points Rewards
        </Card.Header>
        <Card.Body>
          <TableContainer
            sx={{
              backgroundColor: 'rgb(18, 18, 18)'                  
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" className='text-white font-bold'>Rank</TableCell>
                  <TableCell component="th" className='text-white font-bold'>Reward</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(rewards).map(reward => (
                  <TableRow
                    key={reward.level}
                    sx={{
                      backgroundColor: reward.level % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                    }}
                  >
                    <TableCell className='text-white'>{reward.level}</TableCell>
                    <TableCell className='text-white'>{reward.rewards}</TableCell>
                  </TableRow>
                ))}                       
              </TableBody>
            </Table>
          </TableContainer>
        </Card.Body>
        <Card.Footer>
          <div className='d-flex justify-content-between align-items-baseline'>
            <div className='d-flex justify-content-center align-items-baseline'>
              <p>Rank:</p>
              <Input
                size="small"
                type="number" 
                className="text-white font-bold px-2 pt-1 b-r-xs ms-2"
                style={{border: '0.5px solid rgb(74, 85, 104)', width: '15%', textAlign: 'center', fontSize: '13px'}}
                value={selectedLevel} 
                onChange={e => setSelectedLevel(Number(e.target.value))}
                disableUnderline
              />
              <p className='ms-2'>Reward:</p>              
              <Input
                size="small"
                type="text" 
                className="text-white font-bold px-2 pt-1 b-r-xs ms-2"
                style={{border: '0.5px solid rgb(74, 85, 104)', width: '35%', textAlign: 'center', fontSize: '13px'}}
                value={newReward} 
                onChange={e => setNewReward(e.target.value)}
                disableUnderline
              />
              <Button size='small' className='btn-info ms-2' color='inherit' variant='contained'  onClick={handleUpdateReward}>
                Update
              </Button>
            </div>            
          </div>
        </Card.Footer>
      </Card>      
    </>
  );
};
