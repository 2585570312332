import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import axios from 'axios';
import * as config from '../../config';


export const useGetAccountNfts = () => {
  const { address } = useGetAccountInfo();

  const getAccountNfts = async () => {
    if (!Boolean(address) || !address.startsWith('erd1')) {
      return [];
    }

    const { data } = await axios.get(
      `${config.network.apiAddress}/accounts/${address}/nfts?excludeMetaESDT=true&includeFlagged=true&size=10000`
    );
    // console.log(data);
    return data;
  };

  return getAccountNfts;
};
