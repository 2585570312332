import React, { useState, useEffect, useContext } from 'react';
import { Layout } from 'components/Layout/Layout';
import chestImage from 'assets/img/mystery_room.jpg';
import keyImage from 'assets/img/mystery_key.png';
import tokenImage from 'assets/img/logo_no_text.png';
import chestLogo from 'assets/img/chest.png';
import esdtImage from 'assets/img/esdt.png';
import sftImage from 'assets/img/sft.png';
import nftImage from 'assets/img/nft.png';
import styles from './dashboard.module.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import { useMysteryBuyKey } from 'contexts/hooks/transactions/useMysteryBuyKey';
import { useMysteryUnlockChest } from 'contexts/hooks/transactions/useMysteryUnlockChest';
import { xBidToken, mysteryKey } from 'config';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import * as scRequests from '../../contexts/scStakingStonesRequests';
import {Address, BigUIntValue} from '@multiversx/sdk-core/out';
import { useStakingStonesClaim } from 'contexts/hooks/transactions/useStakingStonesClaim';
import { VariantCard } from './components/VariantCard';
import { intlNumberFormat } from 'contexts';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faCopy, faEye, faEyeSlash, faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, onValue } from 'firebase/database';
const database = getDatabase();

const componentsProps = {
	tooltip: {
		sx: {
			maxWidth: '200px',
			backgroundColor: 'black',
			color: 'white',
			fontSize: '14px',
			fontWeight: '400',
			textAlign: 'center',
			borderRadius: '10px',
			padding: '10px',
			top: '-10px'
		},
	},
	arrow: {
		sx: {
			color: 'black',
		},
	},
	TransitionComponent: Fade,
};

interface Reward {
  level: number;
  rewards: string;
}

export const Dashboard = () => {
  const { platformUser, getTopUsersByMonthlyPoints, getTopUsersByTotalPoints, getAllMonthlyRewards, getAllReferralRewards, getAllTotalRewards, getTopUsersByReceivedMonthlyPoints } = useContext(UserContext);

  const [topMUsers, setTopMUsers] = useState([]);
  const [topTUsers, setTopTUsers] = useState([]);
  const [topRUsers, setTopRUsers] = useState([]);
  const fetchTopUsers = async () => {
    const usersM = await getTopUsersByMonthlyPoints(15);
    const usersT = await getTopUsersByTotalPoints(15);
    const usersR = await getTopUsersByReceivedMonthlyPoints(15);

    setTopMUsers(usersM);
    setTopTUsers(usersT);
    setTopRUsers(usersR);
  };

  const [rewardsM, setRewardsM] = useState([{level: 1, rewards: ''}]);
  const [rewardsT, setRewardsT] = useState([{level: 1, rewards: ''}]);
  const [rewardsR, setRewardsR] = useState([{level: 1, rewards: ''}]);
  const fetchRewards = async () => {
    const rewardsMData = (await getAllMonthlyRewards()).filter((reward: any) => reward !== null);
    const rewardsTData = (await getAllTotalRewards()).filter((reward: any) => reward !== null);
    const rewardsRData = (await getAllReferralRewards()).filter((reward: any) => reward !== null);
    setRewardsM(rewardsMData);
    setRewardsT(rewardsTData);
    setRewardsR(rewardsRData);
  };

  useEffect(() => {
    fetchTopUsers();
    fetchRewards();
    const usersUnsubscribe1 = onValue(ref(database, 'users'), () => {
        fetchTopUsers();
    });
    const usersUnsubscribe2 = onValue(ref(database, 'referrals'), () => {
      fetchTopUsers();
  });

    const rewardsUnsubscribe1 = onValue(ref(database, 'monthlyRewards'), () => {
      fetchRewards();
    });
    const rewardsUnsubscribe2 = onValue(ref(database, 'totalRewards'), () => {
      fetchRewards();
    });
    const rewardsUnsubscribe3 = onValue(ref(database, 'referralRewards'), () => {
      fetchRewards();
    });
    
    // Clean up listeners on unmount
    return () => {
      usersUnsubscribe1();
      usersUnsubscribe2();
      rewardsUnsubscribe1();
      rewardsUnsubscribe2();
      rewardsUnsubscribe3();
    };
  }, []);

  //Copy to Clipboard Utility
  function CopyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    toast.success('Successfully copied', {duration: 3000});    
  }

  const [isTableBodyVisible1, setIsTableBodyVisible1] = useState(true);
  const handleToggleTableBody1 = () => {
    setIsTableBodyVisible1(!isTableBodyVisible1);
  };

  const [isTableBodyVisible2, setIsTableBodyVisible2] = useState(false);
  const handleToggleTableBody2 = () => {
    setIsTableBodyVisible2(!isTableBodyVisible2);
  };

  const [isTableBodyVisible3, setIsTableBodyVisible3] = useState(false);
  const handleToggleTableBody3 = () => {
    setIsTableBodyVisible3(!isTableBodyVisible3);
  };

  return (
    <Layout>
      <Container>        
        <div className={`${styles.headerBox} mt-5 d-flex justify-content-between`}>        
          <h4 className='mt-1 mb-1'>Top users by monthly points</h4> 
          <IconButton onClick={handleToggleTableBody1} style={{ color: 'black' }} size='small'>
            <FontAwesomeIcon icon={isTableBodyVisible1 ? faAnglesUp : faAnglesDown} />
          </IconButton>
        </div>

        <div className={`${styles.headerBox2} mt-3`}>
          {topMUsers.length > 0 ? (
              <TableContainer
                sx={{
                  backgroundColor: 'rgb(18, 18, 18)'                  
                }}
              >
                {isTableBodyVisible1 && (
                  <Table
                    sx={{
                      '& .MuiTableCell-root': {
                        border: 'none',
                      },
                      '& .MuiTableRow-root': {
                        borderBottom: 'none',
                      },                      
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell component="th" className='text-white font-bold text-center'>                        
                          Rank
                        </TableCell>
                        <TableCell component="th" className='text-white font-bold'>Display Name</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Wallet Address</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Monthly Points</TableCell>
                        {rewardsM.length && rewardsM[0]?.rewards ? (
                          <TableCell component="th" className='text-white font-bold'>Rewards</TableCell>
                        ): ('')}
                      </TableRow>
                    </TableHead>                  
                    <TableBody>
                      {topMUsers.map((user : any, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                          }}
                        >
                          <TableCell className='text-white text-center'>{index + 1}</TableCell>
                          <TableCell className='text-white'>{user?.displayName}</TableCell>
                          <TableCell className='d-none d-md-table-cell text-white'>
                            <div className='d-flex align-items-center'>
                              <span style={{minWidth: '50%'}}>{user?.walletAddress.slice(0, 8)}...{user?.walletAddress.slice(-8)}</span>
                            </div>
                          </TableCell>
                          <TableCell className='d-sm-none d-table-cell text-white'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <span>{user?.walletAddress.slice(0, 4)}...{user?.walletAddress.slice(-4)}</span>
                            </div>
                          </TableCell>
                          <TableCell className='text-white'>{intlNumberFormat(user?.monthlyPoints, 'en-GB', 3, 3)}</TableCell>
                          {rewardsM.length && rewardsM[0]?.rewards ? (
                            <TableCell className='text-white'>{rewardsM[index] ? rewardsM[index]?.rewards : ''}</TableCell>
                          ): ('')}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            ): (
              <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>No users found.</p>
            )}          
        </div>

        <div className={`${styles.headerBox} d-flex justify-content-between`} style={isTableBodyVisible1 ? {marginTop: '70px'} : {marginTop: '20px'}}>        
          <h4 className='mt-1 mb-1'>Top users by total points</h4>    
          <IconButton onClick={handleToggleTableBody2} style={{ color: 'black' }} size='small'>
            <FontAwesomeIcon icon={isTableBodyVisible2 ? faAnglesUp : faAnglesDown} />
          </IconButton>
        </div>
        <div className={`${styles.headerBox2} mt-3`}>
          {topTUsers.length > 0 ? (
              <TableContainer
                sx={{
                  backgroundColor: 'rgb(18, 18, 18)'                  
                }}
              >
                {isTableBodyVisible2 && (
                  <Table
                    sx={{
                      '& .MuiTableCell-root': {
                        border: 'none',
                      },
                      '& .MuiTableRow-root': {
                        borderBottom: 'none',
                      }
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell component="th" className='text-white font-bold text-center'>Rank</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Display Name</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Wallet Address</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Total Points</TableCell>
                        {rewardsT.length && rewardsT[0]?.rewards ? (
                          <TableCell component="th" className='text-white font-bold'>Rewards</TableCell>
                        ): ('')}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topTUsers.map((user : any, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                          }}
                        >
                          <TableCell className='text-white text-center'>{index + 1}</TableCell>
                          <TableCell className='text-white'>{user?.displayName}</TableCell>
                          <TableCell className='d-none d-md-table-cell text-white'>
                            <div className='d-flex align-items-center'>
                              <span style={{minWidth: '50%'}}>{user?.walletAddress.slice(0, 8)}...{user?.walletAddress.slice(-8)}</span>
                            </div>
                          </TableCell>
                          <TableCell className='d-sm-none d-table-cell text-white'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <span>{user?.walletAddress.slice(0, 4)}...{user?.walletAddress.slice(-4)}</span>
                            </div>
                          </TableCell>
                          <TableCell className='text-white'>{intlNumberFormat(user?.totalPoints, 'en-GB', 3, 3)}</TableCell>
                          {rewardsT.length && rewardsT[0]?.rewards ? (
                            <TableCell className='text-white'>{rewardsT[index] ? rewardsT[index]?.rewards : ''}</TableCell>
                          ): ('')}
                        </TableRow>
                      ))}                       
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            ): (
              <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>No users found.</p>
            )}  
        </div>
        
        <div className={`${styles.headerBox} d-flex justify-content-between`} style={isTableBodyVisible2 ? {marginTop: '70px'} : {marginTop: '20px'}}>    
          <h4 className='mt-1 mb-1'>Top users by referral score</h4>
          <IconButton onClick={handleToggleTableBody3} style={{ color: 'black' }} size='small'>
            <FontAwesomeIcon icon={isTableBodyVisible3 ? faAnglesUp : faAnglesDown} />
          </IconButton>
        </div>
        <div className={`${styles.headerBox2} mt-3`}>
          {topRUsers.length > 0 ? (
              <TableContainer
                sx={{
                  backgroundColor: 'rgb(18, 18, 18)',              
                }}
              >
                {isTableBodyVisible3 && (
                  <Table
                    sx={{
                      '& .MuiTableCell-root': {
                        border: 'none',
                      },
                      '& .MuiTableRow-root': {
                        borderBottom: 'none',
                      }
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell component="th" className='text-white font-bold text-center'>Rank</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Display Name</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Wallet Address</TableCell>
                        <TableCell component="th" className='text-white font-bold'>Monthly Income</TableCell>
                        <TableCell component="th" className='text-white font-bold text-center'>Invited Users</TableCell>
                        {rewardsR.length && rewardsR[0]?.rewards ? (
                          <TableCell component="th" className='text-white font-bold'>Rewards</TableCell>
                        ): ('')}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topRUsers.map((user : any, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                          }}
                        >
                          <TableCell className='text-white text-center'>{index + 1}</TableCell>
                          <TableCell className='text-white'>{user?.displayName}</TableCell>
                          <TableCell className='d-none d-md-table-cell text-white'>
                            <div className='d-flex align-items-center'>
                              <span style={{minWidth: '50%'}}>{user?.walletAddress.slice(0, 8)}...{user?.walletAddress.slice(-8)}</span>
                            </div>
                          </TableCell>
                          <TableCell className='d-sm-none d-table-cell text-white'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <span>{user?.walletAddress.slice(0, 4)}...{user?.walletAddress.slice(-4)}</span>
                            </div>
                          </TableCell>
                          <TableCell className='text-white'>{intlNumberFormat(user?.receivedMonthlyPoints, 'en-GB', 3, 3)}</TableCell>
                          <TableCell className='text-white'>{intlNumberFormat(user?.invitedUsers, 'en-GB', 0, 0)}</TableCell>
                          {rewardsR.length && rewardsR[0]?.rewards ? (
                            <TableCell className='text-white'>{rewardsR[index] ? rewardsR[index]?.rewards : ''}</TableCell>
                          ): ('')}
                        </TableRow>
                      ))}                       
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            ): (
              <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>No users found.</p>
            )}  
        </div>
      </Container>
    </Layout>
  );
};