import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network, xBidToken } from 'config';
import { getSmartContractObj } from 'contexts/scStakingStonesRequests';
import { FixedLengthArray } from 'typesS';
import { Address, Transaction, TransactionWatcher, U64Value, TokenTransfer, BigUIntValue, TokenIdentifierValue } from '@multiversx/sdk-core/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './useSendAndSign';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';
import BigNumber from 'bignumber.js';
import { debug } from 'console';

const watcher = new TransactionWatcher(new ApiNetworkProvider(network.apiAddress), { patienceMilliseconds: 8000 });

const sendAndSignTransactionsWrapped = async (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
): Promise<{
    success: boolean;
    error: string;
    sessionId: string | null;
}> => {
    const result = await sendAndSignTransactions(transactions, displayInfo);
    await watcher.awaitCompleted(transactions[0]);
    return result;
};

export const useStakingStonesUnstake = (sft: string, nonce: number, amount: number) => {
    const { account, address } = useGetAccountInfo();

    const displayInfo = {
        processingMessage: 'Processing unstake transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Unstaked successfully'
    };

    const unstake = async () => {
        const contract = await getSmartContractObj();

        const interaction = contract.methodsExplicit.unStake([new TokenIdentifierValue(sft), new U64Value(nonce), new BigUIntValue(amount)]);
        const transaction = interaction
            .withNonce(account.nonce)
            .withValue(0)
            .withGasLimit(15_000_000)
            .withChainID(network.chainId)
            .buildTransaction();
        
        const sessionId = await sendAndSignTransactionsWrapped(
            [transaction],
            displayInfo
        );
        return sessionId;
    };

    return unstake;
};
