import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scRaffleRequests';
import { Address, Transaction, TransactionWatcher, TokenTransfer } from '@multiversx/sdk-core/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './useSendAndSign';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';

const watcher = new TransactionWatcher(new ApiNetworkProvider(network.apiAddress), { patienceMilliseconds: 8000 });

const sendAndSignTransactionsWrapped = async (
  transactions: Transaction[],
  displayInfo: TransactionsDisplayInfoType
): Promise<{
  success: boolean;
  error: string;
  sessionId: string | null;
}> => {
  const result = await sendAndSignTransactions(transactions, displayInfo);
  await watcher.awaitCompleted(transactions[0]);
  return result;
};

export const useRaffleBuyTickets = (id: number, identifier: string, nonce: number, price: number, ticketsAmount: number) => {
  const { account, address } = useGetAccountInfo();

  const displayInfo = {
    processingMessage: 'Processing buy tickets transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'Tickets bought successfully'
  };

  const  process = async () => {
    const contract = await getSmartContractObj();
    const interaction = contract.methods.buy([id, ticketsAmount]);

    const transfer = TokenTransfer.semiFungible(identifier, nonce, ticketsAmount * price);
    const transaction = interaction
      .withNonce(account.nonce)
      .withValue(0)
      .withSingleESDTNFTTransfer(transfer)
      .withSender(new Address(address))
      .withGasLimit(15_000_000)
      .withChainID(network.chainId)
      .buildTransaction();
    const sessionId = await sendAndSignTransactionsWrapped(
      [transaction],
      displayInfo
    );
    return sessionId;
  };

  return process;
};
