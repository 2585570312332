import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network, xBidToken } from 'config';
import { getSmartContractObj } from 'contexts/scStakingStonesRequests';
import { FixedLengthArray } from 'typesS';
import { Address, Transaction, TransactionWatcher, U64Value, TokenTransfer, BigUIntValue } from '@multiversx/sdk-core/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './useSendAndSign';
import BigNumber from 'bignumber.js';
import { debug } from 'console';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';

const watcher = new TransactionWatcher(new ApiNetworkProvider(network.apiAddress), { patienceMilliseconds: 8000 });

const sendAndSignTransactionsWrapped = async (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
): Promise<{
    success: boolean;
    error: string;
    sessionId: string | null;
}> => {
    const result = await sendAndSignTransactions(transactions, displayInfo);
    await watcher.awaitCompleted(transactions[0]);
    return result;
};

export const useStakingStonesClaim = () => {
    const { account } = useGetAccountInfo();

    const displayInfo = {
        processingMessage: 'Processing claim transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Claimed successfully'
    };

    const claimTokens = async () => {
        const contract = await getSmartContractObj();

        const interaction = contract.methodsExplicit.claimRewards();
        const transaction = interaction
            .withNonce(account.nonce)
            .withGasLimit(15_000_000)
            .withChainID(network.chainId)
            .buildTransaction();
        
        const sessionId = await sendAndSignTransactionsWrapped(
            [transaction],
            displayInfo
        );
        return sessionId;
    };

    return claimTokens;
};
