// slices/accountInfo/marketplaceListings.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getAuctionInfo } from 'api/getAuctionInfo';
import { AuctionInfo } from 'store/types/auctionInfo';
import { AuctionInfoAndUrl } from 'store/types/auctionInfoAndUrl';

interface AuctionInfoState {
  data: AuctionInfoAndUrl | null; // replace with your data type
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AuctionInfoState = {
  data: null,
  status: 'idle',
  error: null
};

export const fetchAuctionInfoData = createAsyncThunk(
  'auctionInfo/fetch',
  async (auctionId: number, { rejectWithValue }) => {
    try {
      const data = await getAuctionInfo(auctionId);
      // console.log('auction data', data);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

const marketplaceListingsSlice = createSlice({
  name: 'auctionInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuctionInfoData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchAuctionInfoData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(
        fetchAuctionInfoData.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = 'failed';
          state.error = action.payload;
        }
      );
  }
});

export default marketplaceListingsSlice.reducer;
