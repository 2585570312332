import {  TokenTransfer, U64Value } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scLotteryRequests';
import  { useContext } from 'react';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
import { FixedLengthArray } from 'typesS';
import { UserContext } from 'contexts';

export const useBuyTicket = (numbers: FixedLengthArray<number[]>, bet: number) => {
  const { platformUser } = useContext(UserContext);
  const { account } = useGetAccountInfo();
  const { sendAndSignTransactionsWrappedTickets, rewardTokenId, currentLotteryId, userTickets } = useContext(Web3LotteryContext);
  const currentTimestamp = new Date().getTime();
  const currentDateTimestamp = Math.floor(currentTimestamp / 1000);


  const displayInfo = {
    processingMessage: 'Processing buy ticket transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'Ticket bought successfully'
  };

  const buyTicket = async () => {    
    const contract = await getSmartContractObj();

    const x: number[] = [];
    numbers.map(value => x.push(value.valueOf()));
    // console.log('bet is', bet);

    const interaction = contract.methodsExplicit.buyTicket([new U64Value(x[0]), new U64Value(x[1]), new U64Value(x[2])]);

    const identifier = rewardTokenId;
    const numDecimals = 18;

    // console.log('bet is ' + bet);
    let transfer = TokenTransfer.fungibleFromAmount(identifier, bet.toLocaleString('en-US', { useGrouping: false }), numDecimals);

    if (rewardTokenId === 'USDC-c76f1f') {
      transfer = TokenTransfer.fungibleFromAmount(identifier, bet.toLocaleString('en-US', { useGrouping: false }), 6);
    }
    
    // console.log('transfer is ' + transfer);

    const transaction = interaction
      .withNonce(account.nonce)
      .withSingleESDTTransfer(transfer)
      .withGasLimit((userTickets.length + 1) * 10000000)
      .withChainID(network.chainId)
      .buildTransaction();
    
    if(platformUser) {
      localStorage.setItem('localTimestamp', currentDateTimestamp.toString());      
      localStorage.setItem('storedPoints', bet.toString());
    }
    const sessionId = await sendAndSignTransactionsWrappedTickets(
      [transaction],
      displayInfo,
      currentLotteryId
    );
    return sessionId;
  };

  return buyTicket;
};
