import React, { useState, useEffect, useContext } from 'react';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import { LoadingScreen } from 'components/LoadingScreen';
import { useMysteryAddReward } from 'contexts/hooks/transactions/useMysteryAddReward';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from 'react-bootstrap/Card';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import * as XLSX from 'xlsx';
import { getDatabase, ref, onValue } from 'firebase/database';
const database = getDatabase();

export const ReferralRanking = () => {
  const { platformUser, getTopUsersByReceivedMonthlyPoints, getAllReferralRewards } = useContext(UserContext);

  const [usersNumber, setUsersNumber] = useState(5);
  const [topUsers, setTopUsers] = useState([]);
  const fetchTopUsers = async () => {
    const users = await getTopUsersByReceivedMonthlyPoints(usersNumber);
    setTopUsers(users);
  };
  
  const [rewards, setRewards] = useState([{level: 1, rewards: ''}]);
  const fetchRewards = async () => {
    const rewardsData = (await getAllReferralRewards()).filter((reward: any) => reward !== null);
    setRewards(rewardsData);
  };

  useEffect(() => {
    fetchTopUsers();
    fetchRewards();

    const usersUnsubscribe = onValue(ref(database, 'users'), () => {
        fetchTopUsers();
    });
    const rewardsUnsubscribe = onValue(ref(database, 'referralRewards'), () => {
      fetchRewards();
    });
    
    return () => {
      usersUnsubscribe();
      rewardsUnsubscribe();
    };
  }, []);

  const handleUsersNumberChange = (event: any) => {
    setUsersNumber(parseInt(event.target.value));    
  };

  // export to excel function
  const exportToExcel = () => {
    const data = topUsers.map((user: any, index: number) => ({
      'Rank': index + 1,
      'Display Name': user?.displayName,
      'Wallet Address': user?.walletAddress,
      'Monthly Income': user?.receivedMonthlyPoints,
      'Referred Users': user?.invitedUsers,
      'Rewards': rewards[index] ? rewards[index]?.rewards : ''
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Referral Ranking');

    const date = new Date();
    const dateString = `${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}`;
    XLSX.writeFile(workbook, `${dateString}_referral_ranking.xlsx`);
  };

  return (
    <>
      <Card bg='dark'>
        <Card.Header>
          Referral Users Ranking
        </Card.Header>
        <Card.Body>
          {topUsers.length > 0 ? (
            <TableContainer
              sx={{
                backgroundColor: 'rgb(18, 18, 18)'                  
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" className='text-white font-bold'>Rank</TableCell>
                    <TableCell component="th" className='text-white font-bold'>Display Name</TableCell>
                    <TableCell component="th" className='text-white font-bold'>Wallet Address</TableCell>
                    <TableCell component="th" className='text-white font-bold'>Monthly Income</TableCell>
                    <TableCell component="th" className='text-white font-bold'>Referred Users</TableCell>
                    <TableCell component="th" className='text-white font-bold'>Rewards</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topUsers.map((user : any, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                      }}
                    >
                      <TableCell className='text-white'>{index + 1}</TableCell>
                      <TableCell className='text-white'>{user?.displayName}</TableCell>
                      <TableCell className='text-white'>{user?.walletAddress}</TableCell>
                      <TableCell className='text-white'>{user?.receivedMonthlyPoints}</TableCell>
                      <TableCell className='text-white'>{user?.invitedUsers}</TableCell>
                      <TableCell className='text-white'>{rewards[index] ? rewards[index]?.rewards : ''}</TableCell>
                    </TableRow>
                  ))}                       
                </TableBody>
              </Table>
            </TableContainer>
          ): (
            <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>No users found.</p>
          )}
        </Card.Body>
        <Card.Footer>
          <div className='d-flex justify-content-between align-items-baseline'>
            <div className='d-flex justify-content-center align-items-baseline'>
              <p>Total Users</p>
              <Input
                size="small"
                type="number" 
                className="text-white font-bold px-2 pt-1 b-r-xs ms-2"
                style={{border: '0.5px solid rgb(74, 85, 104)', width: '25%', textAlign: 'center', fontSize: '13px'}}
                value={usersNumber} 
                onChange={e => handleUsersNumberChange(e)}
                disableUnderline
              />
              <Button size='small' className='btn-info ms-2' color='inherit' variant='contained'  onClick={() => fetchTopUsers()}>
                Refresh
              </Button>
            </div>
            <Button size='small' className='btn-info ms-1' color='inherit' variant='contained'  onClick={exportToExcel}>
              Export
            </Button>
          </div>
        </Card.Footer>
      </Card>      
    </>
  );
};
