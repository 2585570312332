import React, { useState, useEffect } from 'react';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import { LoadingScreen } from 'components/LoadingScreen';
import { useRaffleSetTicketsPayment } from 'contexts/hooks/transactions/useRaffleSetTicketsPayment';
import { raffleChangeOwner  } from 'contexts/hooks/transactions/raffleChangeOwner';
import { getTicketPayment } from 'contexts/scRaffleRequests';
import * as scRequests from '../../contexts/scRaffleRequests';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';

export const TicketsContractDetails = () => {
  const { address } = useGetAccount();
  const [identifier, setIdentifier] = useState('');
  const [nonce, setNonce] = useState(0);
  const [amount, setAmount] = useState(0);
  const [payment, setPayment] = useState({token_identifier: '', token_nonce: 0, amount: 0});

  const getRaffles = async () => {
    const paymentData = await scRequests.getTicketPayment();    
    if(paymentData){
      setPayment(paymentData);
    }
  };

  const handleIdentifierChange = (event: any) => {
    setIdentifier(event.target.value);
  };

  const handleNonceChange = (event: any) => {
    setNonce(event.target.value);
  };

  const handleAmountChange = (event: any) => {
    setAmount(event.target.value);
  };

  useEffect(() => {
    getRaffles();
    const interval = window.setInterval(() => {
      getRaffles();
		}, 2000);
		return () => window.clearInterval(interval);
	}, []);

  const handleRaffleChangeOwner = async (address: any) => {
    try {
      await raffleChangeOwner (address);
    } catch (error) {
      console.error('Error changing owner address:', error);
    }
  };
  

  return (
    <>
      <div className='card dapp-core-component__transactionsTable-styles__transactions-table mb-2'>
        <div className='card-header'>Raffle - Tickets Payment Config</div>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <strong>Contract</strong>
            <span>{config.raffleContractAddress}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div>
            <strong>Current Payment Details</strong>
            <div className='d-flex justify-content-between align-items-center'>
              <u>Identifier</u>
              <span>{payment?.token_identifier}</span>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <u>Nonce</u>
              <span>{payment?.token_nonce.toString()}</span>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <u>Amount</u>
              <span>{payment?.amount.toString()}</span>
            </div>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Ticket Identifier</strong>
            <span><input type="text" className="form-control" id="input1" value={identifier} onChange={e => handleIdentifierChange(e)} /></span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Ticket Nonce</strong>
            <span><input type="number" className="form-control" id="input2" value={nonce} onChange={e => handleNonceChange(e)} /></span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Ticket Amount</strong>
            <span><input type="number" className="form-control" id="input3" value={amount} onChange={e => handleAmountChange(e)} /></span>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <button className='btn btn-primary' onClick={useRaffleSetTicketsPayment(identifier, nonce, amount)}>
            Set Ticket Payment
          </button>
          {/* <button className='btn btn-primary' onClick={() => handleRaffleChangeOwner(address)}>
            Change Owner Address
          </button> */}
        </div>
      </div>      
    </>
  );
};
