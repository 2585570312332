import {
  AbiRegistry,
  Address,
  AddressValue,
  BigUIntValue,
  ResultsParser,
  SmartContract,
  TokenIdentifierValue,
  U64Value
} from '@multiversx/sdk-core';

import { network, mysteryPrizesContractAddress as contractAddress } from 'config';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import contractAbi from './abi/mystery-prizes-sc.abi.json';

export const Provider = new ProxyNetworkProvider(network.gatewayAddress, {
  timeout: 20000 //modify this for testing, change api entirely with blast api
});
export const resultsParser = new ResultsParser();

export const getSmartContractObj = async () => {
  const abiRegistry = await AbiRegistry.create(formatAbiJson(contractAbi));
  return new SmartContract({
    address: new Address(contractAddress),
    abi: abiRegistry
  });
};

const formatAbiJson = (abi: any) => {
  return {
    name: abi.name,
    endpoints: abi.endpoints,
    types: abi.types
  };
};