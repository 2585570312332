// slices/accountInfo/marketplaceListings.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getAuctionHistory } from 'api/getAuctionInfo';
import { AuctionInfo } from 'store/types/auctionInfo';
import { AuctionInfoAndUrl } from 'store/types/auctionInfoAndUrl';

export interface AuctionHistory {
  past_auctions: AuctionInfo[];
}

interface AuctionHistoryState {
  data: AuctionInfoAndUrl[] | null; // replace with your data type
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AuctionHistoryState = {
  data: null,
  status: 'idle',
  error: null
};

export const fetchAuctionHistoryData = createAsyncThunk(
  'auctionHistory/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getAuctionHistory();
      // console.log('auction history', data);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

const marketplaceListingsSlice = createSlice({
  name: 'auctionHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuctionHistoryData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchAuctionHistoryData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.data = action.payload;
        }
      )
      .addCase(
        fetchAuctionHistoryData.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = 'failed';
          state.error = action.payload;
        }
      );
  }
});

export default marketplaceListingsSlice.reducer;
