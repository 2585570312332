import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scLotteryRequests';
import React, { useContext } from 'react';
import { sendAndSignTransactions } from './useSendAndSign';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';

export const useDisableContract = () => {
  const { account } = useGetAccountInfo();
  const {sendAndSignTransactionsWrapped} = useContext(Web3LotteryContext);
  
  const disableContract = async () => {
    const contract = await getSmartContractObj();
    const interaction = contract.methodsExplicit.disableContract();
    const displayInfo = {
      processingMessage: 'Processing disable contract transaction',
      errorMessage: 'An error has occurred while processing the transaction',
      successMessage: 'Disable contract successful'
    };
    const { sessionId, error } = await sendAndSignTransactionsWrapped(
      [
        interaction
          .withNonce(account.nonce)
          .withGasLimit(5_000_000)
          .withChainID(network.chainId)
          .buildTransaction()
      ],
      displayInfo
    );
    // setSessionId(sessionId);
    return sessionId;
  };

  return disableContract;
};
