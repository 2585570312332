import { LoadingScreen } from 'components/LoadingScreen';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
// import { useCloseAuction } from 'contexts/hooks/transactions/useCloseAuction';
import React, { useContext, useEffect, useState } from 'react';
import * as scRequests from '../../contexts/scLotteryRequests';
import { Address } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { useCreateFirstLottery } from 'contexts/hooks/transactions/useCreateFirstLottery';
import { useChangeMaxNumber } from 'contexts/hooks/transactions/useChangeMaxNumber';
import { useChangeMultiplier } from 'contexts/hooks/transactions/useChangeMultiplier';
import { useChangeRewardTokenId } from 'contexts/hooks/transactions/useChangeRewardTokenId';
import { useChangeMaxTokenAmount } from 'contexts/hooks/transactions/useChangeMaxTokenAmount';
import { useChangeMinWithdraw } from 'contexts/hooks/transactions/useChangeMinWithdraw';
import { useChangeNumbersToExtract } from 'contexts/hooks/transactions/useChangeNumbersToExtract';
import { useExtractWinningNumbers } from 'contexts/hooks/transactions/useExtractWinningNumbers';

export const LotteryDetails = () => {
    const { multiplier, maxNumber, maxTokenAmount, minWithdraw, numbersToExtract, rewardTokenId, currentLotteryId, lotteryHistory } = useContext(Web3LotteryContext);


    const [newMaxNumber, setNewMaxNumber] = useState(0);
    const [newMultiplier, setNewMultiplier] = useState(0);
    const [newMaxTokenAmount, setNewMaxTokenAmount] = useState(0);
    const [newRewardTokenId, setNewRewardTokenId] = useState('');
    const [newMinWithdraw, setNewMinWithdraw] = useState(0);
    const [newNumbersToExtract, setNewNumbersToExtract] = useState(0);

    const createFirstLottery = useCreateFirstLottery();
    const changeMaxNumber = useChangeMaxNumber(newMaxNumber);
    const changeMultiplier = useChangeMultiplier(newMultiplier);
    const changeRewardTokenId = useChangeRewardTokenId(newRewardTokenId);
    const changeMaxTokenAmount = useChangeMaxTokenAmount(newMaxTokenAmount);
    const changeMinWithdraw = useChangeMinWithdraw(newMinWithdraw);
    const changeNumbersToExtract = useChangeNumbersToExtract(newNumbersToExtract);
    const extractWinningNumbers = useExtractWinningNumbers();

    const handleNewMaxNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewMaxNumber(e.target.valueAsNumber);
    };
    const handleNewMultiplier = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewMultiplier(e.target.valueAsNumber);
    };
    const handleNewMaxTokenAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewMaxTokenAmount(e.target.valueAsNumber);
    };
    const handleNewRewardTokenId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewRewardTokenId(e.target.value);
    };
    const handleNewMinWithdraw = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewMinWithdraw(e.target.valueAsNumber);
    };
    const handleNewNumbersToExtract = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewNumbersToExtract(e.target.valueAsNumber);
    };

    // console.log(multiplier, maxNumber, maxTokenAmount, minWithdraw, numbersToExtract, rewardTokenId);
    return (
        <>
            <div className='card dapp-core-component__transactionsTable-styles__transactions-table'>

                <div className='card-header'>Lottery details</div>
                <div className='card-body'>
                    <div className='btn btn-primary me-2' onClick={extractWinningNumbers}>
                        Extract Numbers
                    </div>
                    {(currentLotteryId == 0 && lotteryHistory.length == 0) ? (
                        <>
                            <div className='btn btn-primary' onClick={createFirstLottery}>
                                Create first lottery
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='d-flex justify-content-between mt-1'>
                                <strong>Current lottery id</strong>
                                <span>{currentLotteryId}</span>
                            </div>
                        </>
                    )}

                    <div className='d-flex justify-content-between'>
                        <strong>Multiplier</strong>

                        <span className='text-bold p-1'>{multiplier?.valueOf()}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Max token amount</strong>
                        <span className='text-bold p-1'>
                            {new BigNumber(maxTokenAmount? maxTokenAmount : 0).dividedBy(new BigNumber(10).pow(18)).toNumber()}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Reward token id</strong>
                        <span className='text-bold p-1'>{rewardTokenId}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Min token amount for withdraw</strong>
                        <span className='text-bold p-1'>
                            {new BigNumber(minWithdraw ? minWithdraw : 0).dividedBy(new BigNumber(10).pow(18)).toNumber()}
                        </span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Max number </strong>
                        <span className='text-bold p-1'>{maxNumber?.valueOf()}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <strong>Numbers to extract</strong>
                        <span className='text-bold p-1'>{numbersToExtract?.valueOf()}</span>
                    </div>
                </div>
            </div>
            <div className='card mt-2 dapp-core-component__transactionsTable-styles__transactions-table'>
                <div className='card-header'>Lottery settings</div>
                <div className='card-body'>
                    <div className='d-flex justify-content-between mb-3 flex-column'>
                        <div className='d-flex justify-content-between mb-3'>
                            <strong>Multiplier</strong>
                            <div className="col-sm-10">
                                <div className='d-flex flex-row'>
                                    <input type="number" className="form-control" id="inputText2" onChange={e => handleNewMultiplier(e)} />
                                    <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeMultiplier}>Set</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-3'>
                            <strong>Max token amount</strong>
                            <div className="col-sm-10">
                                <div className='d-flex flex-row'>
                                    <input type="number" className="form-control" id="inputText2" onChange={e => handleNewMaxTokenAmount(e)} />
                                    <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeMaxTokenAmount}>Set</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-3'>
                            <strong>Reward token id</strong>
                            <div className="col-sm-10">
                                <div className='d-flex flex-row'>
                                    <input type="text" className="form-control" id="inputText2" onChange={e => handleNewRewardTokenId(e)} />
                                    <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeRewardTokenId}>Set</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-3'>
                            <strong>Min token amount for withdraw</strong>
                            <div className="col-sm-10">
                                <div className='d-flex flex-row'>
                                    <input type="number" className="form-control" id="inputText2" onChange={e => handleNewMinWithdraw(e)} />
                                    <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeMinWithdraw}>Set</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-3'>
                            <strong>Max number</strong>
                            <div className="col-sm-10">
                                <div className='d-flex flex-row'>
                                    <input type="number" className="form-control" id="inputText1" onChange={e => handleNewMaxNumber(e)} />
                                    <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeMaxNumber}>Set</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between mb-3'>
                        <strong>Numbers to extract</strong>
                        <div className="col-sm-10">
                            <div className='d-flex flex-row'>
                                <input type="number" className="form-control" id="inputText3" onChange={e => handleNewNumbersToExtract(e)} />
                                <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeNumbersToExtract}>Set</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
