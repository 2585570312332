import { LoadingScreen } from 'components/LoadingScreen';
import { NftPicker } from 'components/NftPicker';
import { Web3Context } from 'contexts';
import { useCloseAuction } from 'contexts/hooks/transactions/useCloseAuction';
import React, { useContext, useEffect, useState } from 'react';
import * as scRequests from '../../contexts/scRequests';
import { Address } from '@multiversx/sdk-core/out';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import BigNumber from 'bignumber.js';
import { useChangeBidTimeThreshold } from 'contexts/hooks/transactions/useChangeBidTimeThreshold';
import { useChangeBidStep } from 'contexts/hooks/transactions/useChangeBidStep';
import { useChangeMaxBidAmount } from 'contexts/hooks/transactions/useChangeMaxBidAmount';
import { useChangeStartingBidPrice } from 'contexts/hooks/transactions/useChangeStartingBidPrice';
import { getAuctionTokenId } from 'api/getAuctionInfo';

export const AuctionDetails = () => {
  const { accountNfts, currentAuctionId, isInitialLoadDone } = useContext(
    Web3Context
  );
  // console.log('ACCOUNT NFTS', accountNfts);
  const [showNftModalPicker, setShowNftModalPicker] = useState(false);
  const [selectedNft, setSelectedNft] = useState<any>();
  const [newBidTimeThreshold, setNewBidTimeThreshold] = useState(0);
  const [newBidStep, setNewBidStep] = useState(0);
  const [newMaxBidAmount, setNewMaxBidAmount] = useState(0);
  const [newStartingBidPrice, setNewStartingBidPrice] = useState(0);
  const [newBidToken, setNewBidToken] = useState('');
  const [tokenId, setTokenId] = useState('EGLD');

  const closeCurrentAuction = useCloseAuction();
  const changeBidTimeThreshold = useChangeBidTimeThreshold(newBidTimeThreshold);
  const changeBidStep = useChangeBidStep(newBidStep);
  const changeMaxBidAmount = useChangeMaxBidAmount(newMaxBidAmount);
  const changeStartingBidPrice = useChangeStartingBidPrice(newStartingBidPrice);

  const handleNewBidTimeThreshold = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBidTimeThreshold(e.target.valueAsNumber);
  };
  const handleNewBidStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBidStep(e.target.valueAsNumber);
  };
  const handleNewMaxBidAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMaxBidAmount(e.target.valueAsNumber);
  };
  const handleNewStartingPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewStartingBidPrice(e.target.valueAsNumber);
  };
  const handleChooseNftAsset = () => {
    setShowNftModalPicker(true);
  };
  const handleBidToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBidToken(e.target.value.trim());
  };

  // get the auction token id
  const getAuctionTokenData = async () => {
		const newTokendata = await getAuctionTokenId(currentAuctionId);
    if(newTokendata){
      setTokenId(newTokendata);
    }
  };

  useEffect(() => {
		getAuctionTokenData();    
		const interval = window.setInterval(() => {
			getAuctionTokenData();
		}, 2000);
		return () => window.clearInterval(interval);
	}, [useContext(Web3Context)]);
  

  const auctionInfo = useSelector(
    (state: RootState) => state.auctionInfo.auctionData
  );
  const auctionSettings = useSelector(
    (state: RootState) => state.auctionInfo.auctionSettings
  );

  // console.log('admin settings', auctionInfo, auctionSettings, isInitialLoadDone);

  return auctionSettings.status === 'failed' ||
    auctionSettings.data === null
    // ||
    // auctionInfo.data === undefined ||
    // auctionInfo.status === 'failed' ||
    // auctionInfo.data === null ||
    // !isInitialLoadDone 
    ?
    (
      <LoadingScreen />
    ) : (
      <>
        <div className='card dapp-core-component__transactionsTable-styles__transactions-table'>
          <NftPicker
            showModal={showNftModalPicker}
            nfts={accountNfts}
            onSelectNft={(nft) => {
              setSelectedNft(nft);
              setShowNftModalPicker(false);
            }}
            onDismissModal={() => setShowNftModalPicker(false)}
            tokenId={newBidToken}
          />
          <div className='card-header'>Auction details</div>
          <div className='card-body'>
            {auctionSettings.data.is_auction_open && (
              <>
                <div className='d-flex justify-content-between'>
                  <strong>Current auction id</strong>
                  <span>{currentAuctionId}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <strong>Auction asset</strong>
                  <span>{auctionInfo?.data?.asset?.full_identifier ?? ''}</span>
                </div>
                <div className='d-flex justify-content-between'>
                  <strong>Last bid</strong>

                  <span>
                    {new BigNumber(
                      auctionInfo?.data?.last_bid_price ?? '0'
                    ).dividedBy(new BigNumber(10).pow(18)).toNumber()} {tokenId}
                  </span>
                </div>
              </>
            )}

            <div className='d-flex justify-content-between'>
              <strong>Auction state</strong>
              {auctionSettings.data.is_auction_open && (
                <span className='bg-success text-bold p-1'>OPEN</span>
              )}
              {!auctionSettings.data.is_auction_open && (
                <span className='bg-danger text-bold p-1'>CLOSED</span>
              )}
            </div>
          </div>
          <div className='card-footer d-flex justify-content-center w-100'>
            {auctionSettings.data.is_auction_open && (
              <div className='btn btn-primary' onClick={closeCurrentAuction}>
                Close auction
              </div>
            )}
            {!auctionSettings.data.is_auction_open && (
              <>
                <div className='btn btn-primary' onClick={handleChooseNftAsset}>
                  Create auction
                </div>
                <p className='card-text text-center mt-2 ms-2'>
                  {selectedNft === undefined
                    ? 'No NFT selected yet'
                    : `Selected NFT: ${selectedNft.identifier}`}
                </p>
              </>
            )}
          </div>


        </div>
        <div className='card mt-5 dapp-core-component__transactionsTable-styles__transactions-table'>
          <div className='card-header mt'>Auction settings</div>
          <div className='card-body'>
            <div className='d-flex justify-content-between mb-3'>
              <strong>Token ID <small>(leave empty for egld)</small></strong>
              <div className="col-sm-10">
                <div className='d-flex flex-row'>
                  <input type="text" className="form-control" id="inputText5" onChange={e => handleBidToken(e)} />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between mb-3'>
              <strong>Bid time threshold</strong>
              <div className="col-sm-10">
                <div className='d-flex flex-row'>
                  <input type="number" className="form-control" id="inputText1" onChange={e => handleNewBidTimeThreshold(e)} />
                  <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeBidTimeThreshold}>Set</button>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between mb-3'>
              <strong>Bid fixed amount step</strong>
              <div className="col-sm-10">
                <div className='d-flex flex-row'>
                  <input type="number" className="form-control" id="inputText2" onChange={e => handleNewBidStep(e)} />
                  <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeBidStep}>Set</button>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between mb-3'>
              <strong>Max bid amount</strong>
              <div className="col-sm-10">
                <div className='d-flex flex-row'>
                  <input type="number" className="form-control" id="inputText3" onChange={e => handleNewMaxBidAmount(e)} />
                  <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeMaxBidAmount}>Set</button>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <strong>Starting bid price</strong>
              <div className="col-sm-10">
                <div className='d-flex flex-row'>
                  <input type="number" className="form-control" id="inputText4" onChange={e => handleNewStartingPrice(e)} />
                  <button className="btn btn-outline-primary ms-1" type="button" id="button-addon2" onClick={changeStartingBidPrice}>Set</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};
