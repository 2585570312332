import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';
import React, { useState } from 'react';
import * as config from '../../config';
import * as scRequests from '../../contexts/scMysteryPrizesRequests';
import { Layout } from 'components/Layout/Layout';
import { MonthlyRanking } from './MonthlyRanking';
import { TotalRanking } from './TotalRanking';
import { ReferralRanking } from './ReferralRanking';
import { MonthlyRewards } from './MonthlyRewards';
import { TotalRewards } from './TotalRewards';
import { ReferralRewards } from './ReferralRewards';
import { UpdatePoints } from './UpdatePoints';

export const AdminDatabase = () => {
  const { address } = useGetAccount();
  const [inputText, setInputText] = React.useState('');
  const [inputTokenId, setInputTokenId] = React.useState('');
  const [inputNonce, setInputNonce] = React.useState('');
  const [inputAmount, setInputAmount] = React.useState('');

  if(address.toString() === config.adminAddress){
    return (
      <Layout>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h1 className='mt-5 mb-5'>
                <strong>Database admin panel</strong>
              </h1>
            </div>

            <div className='col-lg-12 col-sm-12 mb-5'>
              <MonthlyRanking />
            </div>

            <div className='col-lg-12 col-sm-12 mt-5 mb-5'>
              <TotalRanking />
            </div>

            <div className='col-lg-12 col-sm-12 mt-5 mb-5'>
              <ReferralRanking />
            </div>

            <div className='col-lg-4 col-sm-12 mt-5 mb-5'>
              <MonthlyRewards />
            </div>

            <div className='col-lg-4 col-sm-12 mt-5 mb-5'>
              <TotalRewards />
            </div>

            <div className='col-lg-4 col-sm-12 mt-5 mb-5'>
              <ReferralRewards />
            </div>

            <div className='col-lg-4 col-sm-12 mt-5 mb-5'>
              <UpdatePoints />
            </div>

          </div>
        </div>
      </Layout>
    );
  }else{
    return (
      <Layout>
        <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>Page visible to admin only</p>
      </Layout>
    );
  }
};
