import React, { useState, useContext, useEffect } from 'react';
import styles from './account.module.scss';
import { Layout } from 'components/Layout/Layout';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import { Footer } from 'components/Layout/Footer';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import {logout as walletLogout} from 'helpers';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import { Box, Typography, Paper, Avatar, Divider} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@mui/icons-material/Close';
import { intlNumberFormat } from 'contexts';
import { getDatabase, ref, onValue, get, update } from 'firebase/database';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

const database = getDatabase();

const formatTimestampToDate = (timestamp: any) => {
  const date = new Date(Number(timestamp));
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  return `${day}-${month}-${year}`;
};

const levelXp = JSON.parse(process.env.REACT_APP_LEVEL_XP ? process.env.REACT_APP_LEVEL_XP : '{}');
const levelMultiplier = JSON.parse(process.env.REACT_APP_LEVEL_MULTIPLIER ? process.env.REACT_APP_LEVEL_MULTIPLIER : '{}');

export const MyAccount = () => {
  const { platformUser, logout, updateReferralPoints, getReferredUsers } = useContext(UserContext);
  const referralLink = `https://www.xbid.app/unlock?isLoginVisible=false&referralCode=${platformUser?.referralCode}`;
  const [referredUsers, setReferredUsers] = useState([]);

  const [tab, setTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const fetchReferredUsers = async () => {
    if (platformUser) {
      const users = await getReferredUsers(platformUser.uid);
      if (users) {
        setReferredUsers(Object.values(users));
      }
    }
  };

  useEffect(() => {
    fetchReferredUsers();

    const unsubscribe = onValue(ref(database, 'referrals'), () => {
      fetchReferredUsers();
    });    
    return () => unsubscribe();
  }, [platformUser]);

  const [showUsername, setShowUsername] = useState(false);
  const toggleUsernameVisibility = () => {
    setShowUsername(!showUsername);
  };

  //Copy to Clipboard Utility
  function CopyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    toast.success('Successfully copied', {duration: 3000});    
  }

  // Rewards based per lv modal
  const [showRewardsSubtitle, setShowRewardsSubtitle] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
	const handleCloseRewardModal = () => {
    setShowRewardModal(false);
    setShowRewardsSubtitle(false);
  };

  // Levels upgrade modal  
  const [showLvModal, setShowLvModal] = useState(false);
	const handleCloseLvModal = () => setShowLvModal(false);

  //edit the display name
  const [isEditing, setIsEditing] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState(platformUser?.displayName || '');
  const userId = platformUser?.uid;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCheckClick = async () => {
    if (userId) {
      try {
        const updates: any = {};
        updates[`users/${userId}/displayName`] = newDisplayName;

        // Fetch the referrer ID for the current user
        const userRef = ref(database, `users/${userId}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          const referrerId = userData.referrerId;

          if (referrerId) {
            updates[`referrals/${referrerId}/${userId}/displayName`] = newDisplayName;
          }
        }

        await update(ref(database), updates);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating display name:', error);
      }
    }
  };

  return (
    <Layout>
      <div className='container'>
        <div className={`${styles.headerBox2} mt-5`}>
          <Row>
            <Col xs={12}>
              <Tabs
                value={tab}
                onChange={handleChange}
                scrollButtons
                allowScrollButtonsMobile
                variant="fullWidth"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#00E676',
                  },
                  '& .MuiTab-root': {
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    borderLeft: '1px dashed #00E676',
                    '&:first-of-type': {
                      borderLeft: 'none',
                    },
                  },
                  '& .Mui-selected': {
                    color: '#00E676 !important',
                  },
                  background: 'linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/media/Rectangle%2091.svg") no-repeat, lightgray',
                  backgroundPosition: '50 -75px',
                  backgroundSize: 'cover',
                  borderRadius: '10px'
                }}
              >
                <Tab icon={<AccountCircleIcon sx={{ marginTop: '-5px' }} />} iconPosition="start" label="Profile Details" />
                <Tab icon={<GroupAddIcon sx={{ marginTop: '-5px' }} />} iconPosition="start" label="Referral Details" />
              </Tabs>
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col>
              {tab === 0 && (
                <div className='py-3 ps-1'>
                  {platformUser ? (
                    <TableContainer sx={{backgroundColor: 'black'}}>
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            border: 'none',
                          },
                          '& .MuiTableRow-root': {
                            borderBottom: 'none',
                          }
                        }}
                      >
                        <TableBody>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                            <TableCell component="th" className='text-white'>Username:</TableCell>
                            <TableCell align='right' className='text-green-A200'>
                              {showUsername ? platformUser?.username : '••••••••'}
                              <IconButton onClick={toggleUsernameVisibility}>
                                {showUsername ? <VisibilityOff style={{color: 'white'}} />: <Visibility style={{color: 'white'}} />}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                            <TableCell component="th" className='text-white'>Display Name:</TableCell>
                            <TableCell align='right' className='text-green-A200'>
                            {isEditing ? (
                              <>
                                <TextField
                                  variant="outlined"
                                  color="info"                                  
                                  size='small'
                                  value={newDisplayName}                
                                  onChange={(e) => setNewDisplayName(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9a-zA-Z. ]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    style: {color: 'white', fontSize: '14px', background: 'black'}
                                  }}
                                  InputLabelProps={{
                                      style: {color: 'white', fontSize: '15px'}
                                  }}
                                  className="text-white b-r-xs"
                                />
                                <IconButton onClick={handleCheckClick}>
                                  <CheckIcon className="text-white" />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                {platformUser?.displayName}
                                <IconButton onClick={handleEditClick}>
                                  <EditIcon className="text-white" style={{fontSize: '18px', marginTop: '-3px'}}/>
                                </IconButton>
                              </>
                            )}
                            </TableCell>
                          </TableRow>
                          <TableRow className='d-none d-md-table-row' sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                            <TableCell component="th" className='text-white'>Wallet:</TableCell>
                            <TableCell align='right' className='text-green-A200'>
                              {platformUser?.walletAddress}
                              <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => CopyToClipboard(platformUser?.walletAddress)}><FontAwesomeIcon icon={faCopy} /></span>
                            </TableCell>
                          </TableRow>
                          <TableRow className='d-sm-none d-table-row' sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                            <TableCell component="th" className='text-white'>Wallet:</TableCell>
                            <TableCell align='right' className='text-green-A200'>
                              {platformUser?.walletAddress.slice(0, 5)} ... {platformUser?.walletAddress.slice(58, 62)}
                              <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => CopyToClipboard(platformUser?.walletAddress)}><FontAwesomeIcon icon={faCopy} /></span>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                            <TableCell component="th" className='text-white'>Overall Points:</TableCell>
                            <TableCell align='right' className='text-green-A200'>{intlNumberFormat(platformUser?.totalPoints, 'en-GB', 4, 4)}</TableCell>
                          </TableRow>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                            <TableCell component="th" className='text-white'>Current Month Points:</TableCell>
                            <TableCell align='right' className='text-green-A200'>{intlNumberFormat(platformUser?.monthlyPoints, 'en-GB', 4, 4)}</TableCell>
                          </TableRow>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                            <TableCell component="th" className='text-white'>XP (Experience):</TableCell>
                            <TableCell align='right' className='text-green-A200'>{intlNumberFormat(platformUser?.xp, 'en-GB', 4, 4)}</TableCell>
                          </TableRow>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                            <TableCell component="th" className='text-white'>Level:</TableCell>
                            <TableCell align='right' className='text-green-A200'>
                              {platformUser?.level}
                              <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => setShowLvModal(true)}><FontAwesomeIcon icon={faInfoCircle} /></span>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                            <TableCell component="th" className='text-white'>Points rewards: </TableCell>
                            <TableCell align='right' className='text-green-A200'>
                              {platformUser?.multiplier ? (platformUser?.multiplier) * 100 : 0}%
                              <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => setShowRewardModal(true)}><FontAwesomeIcon icon={faInfoCircle} /></span>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                            <TableCell component="th" className='text-white'>Current Title:</TableCell>
                            <TableCell align='right' className='text-green-A200 text-capitalize'>{platformUser?.title}</TableCell>
                          </TableRow>
                          <TableRow  sx={{backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                            <TableCell component="th" className='text-white'>Invited users:</TableCell>
                            <TableCell align='right' className='text-green-A200 text-capitalize'>{platformUser?.referredUsers}</TableCell>
                          </TableRow>
                          <TableRow  sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                            <TableCell component="th" className='text-white'>Joining Date</TableCell>
                            <TableCell align='right' className='text-green-A200 text-capitalize'>{platformUser ? formatTimestampToDate(platformUser.createdAt) : ''}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ):(
                    <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>xBid account not connected</p>
                  )}
                </div>
              )}
              {tab === 1 && (
                <div className='py-3 ps-1'>
                  {platformUser ? (
                    <p className='text-justified'>Invite users to join us and get rewarded. This is your referral link: 
                      <span style={{color: '#00E676', wordBreak: 'break-all', wordWrap: 'break-word', overflowWrap: 'break-word'}}> {referralLink}</span>
                      <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => CopyToClipboard(referralLink)}><FontAwesomeIcon icon={faCopy} /></span>, or when signing up, they must use this code: 
                      <span style={{color: '#00E676'}}> {platformUser?.referralCode}</span>
                      <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => CopyToClipboard(platformUser?.referralCode)}><FontAwesomeIcon icon={faCopy} /></span> (better for xPortal App). Each user will give you a percentage of points and xp used by them based on your current level.
                      <span className='ms-1' style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {setShowRewardModal(true);}}>Open rewards list</span>
                    </p>
                  ):('')}
                  {referredUsers.length > 0 ? (
                    <TableContainer sx={{backgroundColor: 'black'}}>
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            border: 'none',
                          },
                          '& .MuiTableRow-root': {
                            borderBottom: 'none',
                          }
                        }}
                      >
                        <TableHead>
                          <TableRow sx={{backgroundColor: 'rgb(10, 10, 10)'}}>
                            <TableCell component="th" className='text-white font-bold'>Reffered Name</TableCell>
                            <TableCell component="th" className='text-white font-bold'>Wallet Address</TableCell>
                            <TableCell component="th" className='text-white font-bold' align='center'>Current Month Income</TableCell>
                            <TableCell component="th" className='text-white font-bold' align='center'>Total Income</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {referredUsers.map((refUser: any, index)=> (
                            <TableRow
                              key={index}
                              sx={{
                                backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                              }}
                            >
                              <TableCell component="th" className='text-white text-capitalize'>{refUser?.displayName}</TableCell>
                              <TableCell className='d-none d-md-table-cell text-white'>
                                <div className='d-flex align-items-center'>
                                  <span style={{minWidth: '80%'}}>{refUser?.walletAddress}</span>
                                  <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => CopyToClipboard(refUser?.walletAddress)}><FontAwesomeIcon icon={faCopy} /></span>
                                </div>
                              </TableCell>
                              <TableCell className='d-sm-none d-table-cell text-white'>
                                <div className='d-flex justify-content-center align-items-center'>
                                  <span>{refUser?.walletAddress.slice(0, 4)}...{refUser?.walletAddress.slice(58, 62)}</span>
                                  <span className='ms-2 text-white' style={{cursor: 'pointer'}} onClick={() => CopyToClipboard(refUser?.walletAddress)}><FontAwesomeIcon icon={faCopy} /></span>
                                </div>
                              </TableCell>
                              <TableCell className='text-white' align='center'>{intlNumberFormat(refUser?.receivedMonthlyPoints, 'en-GB', 4, 4)}</TableCell>
                              <TableCell className='text-white' align='center'>{intlNumberFormat(refUser?.receivedPoints, 'en-GB', 4, 4)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                    </Table>
                  </TableContainer>
                  ) : (
                    <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>You have no reffered users</p>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>

      {/* Rewards based per lv modal*/}
      <Dialog
        open={showRewardModal}
        onClose={handleCloseRewardModal}
        fullWidth
        sx={{
          backgroundColor: 'transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#180437',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(6, 11, 40, 0.74)',
          },
        }}
      > 
        <div className={styles.numbersModal}>       
          <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
            <span className='text-center'>Rewards Milestones</span>
          </DialogTitle>
          <IconButton
            size='small'
            aria-label='close'
            onClick={handleCloseRewardModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'red'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="p-2 text-justified">
              <small>* You will get the level corresponding percentage of the amount of points / xp received by each invited player</small>
            </div>
            <div className="p-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>              
              <TableContainer
                sx={{
                  backgroundColor: 'rgb(18, 18, 18)'                  
                }}
              >
                <Table
                  sx={{
                    '& .MuiTableCell-root': {
                      border: 'none',
                    },
                    '& .MuiTableRow-root': {
                      borderBottom: 'none',
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" className='text-white font-bold text-center'>Level</TableCell>
                      <TableCell component="th" className='text-white font-bold text-center'>Points rewards</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(levelMultiplier).map(([key, value]: [key : string, value : any], index) => (
                      <TableRow
                        key={key}
                        sx={{
                          backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                        }}
                      >
                        <TableCell className={`${platformUser?.level >= key ? 'text-green-A200' : 'text-white'} text-center`}>{key}</TableCell>
                        <TableCell className={`${platformUser?.level >= key ? 'text-green-A200' : 'text-white'} text-center`}>{(value * 100).toFixed(0)}%</TableCell>
                      </TableRow>
                    ))}                       
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* Levels modal*/}
      <Dialog
        open={showLvModal}
        onClose={handleCloseLvModal}
        fullWidth
        sx={{
          backgroundColor: 'transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#180437',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(6, 11, 40, 0.74)',
          },
        }}
      > 
        <div className={styles.numbersModal}>       
          <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
            <span className='text-center'>Levels Milestones</span>
          </DialogTitle>
          <IconButton
            size='small'
            aria-label='close'
            onClick={handleCloseLvModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'red'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="p-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <TableContainer
                sx={{
                  backgroundColor: 'rgb(18, 18, 18)',
                  width: {
                    xs: '100%',
                    lg: '80%',
                  }
                }}
              >
                <Table
                  sx={{
                    '& .MuiTableCell-root': {
                      border: 'none',
                    },
                    '& .MuiTableRow-root': {
                      borderBottom: 'none',
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>Level</TableCell>
                      <TableCell component="th" sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>XP / Required XP</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(levelXp).map(([key, value]: [key : string, value : any], index) => (
                      <TableRow
                        key={key}
                        sx={{
                          backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                        }}
                      >
                        <TableCell className={`${platformUser?.xp >= value ? 'text-green-A200' : 'text-white'} text-center`}>{key}</TableCell>
                        <TableCell className={`${platformUser?.xp >= value ? 'text-green-A200' : 'text-white'} text-center`}>{platformUser?.xp} / {value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </Layout>
  );
};



