import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import BigNumber from 'bignumber.js';

export const BIG_NUMBER_ROUNDING_MODE = BigNumber.ROUND_FLOOR;
export const parseBigNumber = (value: BigNumber.Value | null): BigNumber => {
  try {
    if (value != null) {
      return new BigNumber(value);
    }
  } catch (err) {
    //
  }
  return new BigNumber(0);
};

const localDecimalSeparator = 0.1.toLocaleString().replace(/\d/g, '');
const bgFormatter = {
  decimalSeparator: localDecimalSeparator,
  groupSeparator: localDecimalSeparator == '.' ? ',' : '.',
  groupSize: 3
};

export const convertBigNumberToLocalString = (
  value: BigNumber.Value,
  precision?: number,
): string => {
  let bv = parseBigNumber(value);
  bv = bv.isNaN() ? new BigNumber(0) : bv;
  let v = bv.toFormat(precision ? precision : 4, BIG_NUMBER_ROUNDING_MODE, bgFormatter);
  
  // remove trailing zeros
  if (v.search(localDecimalSeparator) >= 0) {
    v = v.replace(/\.?0+$/, '');
  }  
  return v;
};

export default function ProgressBar({ totalCount, leftCount, activeColor} : {totalCount: any, leftCount: any, activeColor: any}) {

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 4,
		borderRadius: 5,
		padding: '3px 0',
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: '#2d3339',
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: activeColor,
		},
	}));

	return (
		<>
			<div className='mt-2'>
				<BorderLinearProgress
					variant='determinate'
					value={((totalCount - leftCount) / totalCount) * 100}
				/>
			</div>
			<div
				className='d-flex justify-content-between mt-2'
				style={{ fontSize: '.8rem', color: '#b6b3b3' }}
			>
        <span>
          {convertBigNumberToLocalString(totalCount - leftCount)}{' '}
          {`(${convertBigNumberToLocalString(
            ((totalCount - leftCount) / totalCount) * 100,
            2,
          )}%)`}
        </span>
				<span>{convertBigNumberToLocalString(totalCount)}</span>
			</div>
		</>
	);
}