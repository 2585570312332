import React, { useEffect, useState } from 'react';
import {
  useGetAccountInfo,
  useGetLoginInfo
} from '@multiversx/sdk-dapp/hooks/account';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import * as scRequests from './scRequests';
import { useGetAccountNfts } from './hooks/useGetAccountNfts';
import { useCustomDispatch } from 'store/useCustomDispatch';
import { adminAddress, network } from 'config';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Transaction, TransactionWatcher } from '@multiversx/sdk-core/out';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './hooks/transactions/useTest';
import { time } from 'console';

export interface IWeb3Context {
  isInitialLoadDone: boolean;
  accountNfts: any[];
  currentAuctionId: number;
  refreshState: () => void;
  trackTransaction: (transaction: Transaction) => Promise<void>;
  sendAndSignTransactionsWrapped: (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
  ) => Promise<{
    success: boolean;
    error: string;
    sessionId: string | null;
  }>;
}

export const defaultState: IWeb3Context = {
  isInitialLoadDone: false,
  accountNfts: [],
  currentAuctionId: 0,
  refreshState: () => {
    /* do nothing */
  },
  trackTransaction: async () => {
    //
  },
  sendAndSignTransactionsWrapped: async (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
  ) => {
    throw 'Not initialized';
  }
};

export const Web3Context = React.createContext<IWeb3Context>(defaultState);

const watcher = new TransactionWatcher(
  new ApiNetworkProvider(network.apiAddress)
);

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const {
    account: { address, balance }
  } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const { dispatchAuctionData, dispatchAuctionHistory, dispatchAuctionInfo, dispatchAuctionSettings } = useCustomDispatch();
  const auctionSettings = useSelector(
    (state: RootState) => state.auctionInfo.auctionSettings
  );
  const auctionInfo = useSelector(
    (state: RootState) => state.auctionInfo.auctionData
  );

  const trackTransaction = async (transaction: Transaction) => {
    await watcher.awaitCompleted(transaction);
    // dispatchAuctionData(currentAuctionId);
    dispatchAuctionInfo(currentAuctionId);
    // dispatchAuctionSettings();
    dispatchAuctionHistory();
    if (adminAddress === address) {
      getAccountNfts().then((nfts) => {
        setAccountNfts(nfts);
      });
    }
  };

  const sendAndSignTransactionsWrapped = async (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
  ): Promise<{
    success: boolean;
    error: string;
    sessionId: string | null;
  }> => {
    const result = await sendAndSignTransactions(transactions, displayInfo);
    await trackTransaction(transactions[0]);
    return result;
  };

  const getAccountNfts = useGetAccountNfts();

  const [accountNfts, setAccountNfts] = useState([]);
  const [currentAuctionId, setCurrentAuctionId] = React.useState(0);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

  const refreshState = async () => {
    // console.log('refresh state');
    const currentAuctionIdSC = await scRequests.getLastAuctionId();
    setCurrentAuctionId(currentAuctionIdSC ? currentAuctionIdSC.toNumber() : 0);

    // dispatchAuctionInfo(currentAuctionIdSC);
    dispatchAuctionSettings();
    
    if (!isLoggedIn) {
      return;
    }

    await refreshAccount();
  };

  useEffect(() => {
    if (adminAddress === address) {
      getAccountNfts().then((nfts) => {
        setAccountNfts(nfts);
      });
    }
  }, [address, isLoggedIn]);


  useEffect(() => {
    // console.log('auction settings dispatched');
    dispatchAuctionSettings();
  }, []);

  // console.log(auctionSettings);


  useEffect(() => {
    if (isAuctionIdValid(currentAuctionId)) {
      // console.log('auction info dispatched');
      dispatchAuctionInfo(currentAuctionId);
    }
    dispatchAuctionHistory();
    // console.log('auction history dispatched');
  }, [currentAuctionId]);

  useEffect(() => {
    // console.log('interval');
    const interval = setInterval(
      () => refreshState(),
      3000
    );
    return () => clearInterval(interval);

  }, [currentAuctionId]);

  useEffect(() => {
    if (isInitialLoadDone === false) {
      setIsInitialLoadDone(
        auctionSettings.status === 'succeeded' &&
        auctionInfo.status === 'succeeded'
      );
    }
  }, [auctionSettings.status, auctionInfo.status]);

  const isAuctionIdValid = (id: number | null | undefined) => {
    return (
      currentAuctionId !== undefined &&
      currentAuctionId !== null &&
      currentAuctionId > 0
    );
  };

  return (
    //return data from variables from sc
    <Web3Context.Provider
      value={{
        isInitialLoadDone,
        accountNfts,
        currentAuctionId,
        refreshState,
        trackTransaction,
        sendAndSignTransactionsWrapped
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

  // useEffect(() => {
  //   if (isAuctionIdValid(currentAuctionId)) {
  //     // console.log('k');
  //     dispatchAuctionData(currentAuctionId);
  //   }
  //   dispatchAuctionHistory();
  // }, [currentAuctionId]);

  
  // useEffect(() => {
  //   refreshState().then((_) => {
  //     //
  //   });
  // }, [isLoggedIn, address]);

  // useEffect(() => {
  //   dispatchAuctionSettings();
  // }, [auctionSettings.status, auctionSettings.data]);