import React from 'react';
import './style.css';

export const NftCard = ({
  nft,
  isSelected,
  onSelect
}: {
  nft: any;
  isSelected: boolean;
  onSelect: () => void;
}) => {
  return (
    <div
      className={`card p-2 selectable ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
    >
      <div className='card-header'>
        <h3>{nft.name}s</h3>
      </div>
      <div className='card-body'>
        <img src={nft.url} className='img-responsive' />
      </div>
      <div className='card-footer text-center'>
        <small>{nft.identifier}</small>
      </div>
    </div>
  );
};
