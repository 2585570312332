import React, { createContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  onAuthStateChanged, 
  signInWithEmailAndPassword, 
  signOut, 
  createUserWithEmailAndPassword, 
  updateProfile
} from 'firebase/auth';
import { getDatabase, ref, child, get, query, orderByChild, equalTo, push, update, set, onValue, runTransaction, limitToLast, limitToFirst } from 'firebase/database';
import {orderBy} from 'firebase/firestore';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

const levelXp = JSON.parse(process.env.REACT_APP_LEVEL_XP ? process.env.REACT_APP_LEVEL_XP : '');
const levelMultiplier = JSON.parse(process.env.REACT_APP_LEVEL_MULTIPLIER ? process.env.REACT_APP_LEVEL_MULTIPLIER : '');

// Initialize Firebase with environment variables
 const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export function intlNumberFormat(number, locales, minDigits, maxDigits) {
  const options = {
      minimumFractionDigits: minDigits !== undefined ? minDigits : 2,
      maximumFractionDigits: maxDigits !== undefined ? maxDigits : 2,
  };

  return new Intl.NumberFormat(locales ? locales : 'en-GB', options).format(number);
}

// Generate a random alphanumeric referral code
const generateReferralCode = () => {  
  return Math.random().toString(36).substring(2, 14).toUpperCase();
};

// Function to get user data by UID
const getUserData = async (uid) => {
  try {
    const userRef = ref(database, `users/${uid}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.error('No user data found for UID:', uid);
      return null;
    }
  } catch (error) {
    console.error('Error getting user data:', error);
    return null;
  }
};

// Function to get user data by UID
const getRefUserData = async (referrerId, referredId) => {
  try {
    const userRef = ref(database, `referrals/${referrerId}/${referredId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.error('No user data found for UID:', uid);
      return null;
    }
  } catch (error) {
    console.error('Error getting user data:', error);
    return null;
  }
};

// Function to check if a referral code exists and is unused
const checkReferralCode = async (referralCode) => {
  try {
    const usersRef = ref(database, 'users');
    
    // Check if the "users" table exists
    return get(usersRef).then(async (snapshot) => {
      if (snapshot.exists()) {
        const q = query(usersRef, orderByChild('referralCode'), equalTo(referralCode));
        const snapshot = await get(q);
        const userData = snapshot.val();

        if (userData) {
          return false;
        } else {
          return true;
        }
      } else {
        console.log('No users node');
        return true;
      }
    });
  } catch (error) {
    console.error('Error checking referral code:', error);
    return false;
  }
};

// Function to check if the wallet code is unique
const checkWalletAddress = async (walletAddress) => {
  try {
    const usersRef = ref(database, 'users');
    
    // Check if the "users" table exists
    return get(usersRef).then(async (snapshot) => {
      if (snapshot.exists()) {
        const q = query(usersRef, orderByChild('walletAddress'), equalTo(walletAddress));
        const snapshot = await get(q);
        const userData = snapshot.val();

        if (userData) {
          return false;
        } else {
          return true;
        }
      } else {
        console.log('No users node');
        return true;
      }
    });
  } catch (error) {
    console.error('Error checking wallet address:', error);
    return false;
  }
};

// Function to get the user uid by referral code (referrer user)
const getUserByReferralCode = async (referralCode) => {
  try {
    const usersRef = ref(database, 'users');
    
    // Check if the "users" table exists
    return get(usersRef).then(async (snapshot) => {
      if (snapshot.exists()) {
        const q = query(usersRef, orderByChild('referralCode'), equalTo(referralCode));
        const snapshot = await get(q);
        const userData = snapshot.val();

        if (userData) {
          const uid = Object.keys(userData)[0];
          return uid;
        } else {
          return null;
        }
      } else {
        console.log('No users node');
        return null;
      }
    });
  } catch (error) {
    console.error('Error getting users by referral code:', error);
    return false;
  }
};

// Function to update referredUsers count for the referrer
const updateReferrerUsersCount = async (referralCode) => {
  try {
    const referrerUid = await getUserByReferralCode(referralCode);
    if (referrerUid) {
      const referrerRef = ref(database, `users/${referrerUid}`);
      // Get the current referredUsers count
      let referredUsersCount = 0;
      onValue(referrerRef, (snapshot) => {
        const referrerData = snapshot.val();
        referredUsersCount = referrerData.referredUsers || 0;
      });
      // Increment the referredUsers count
      referredUsersCount++;
      // Update the referredUsers count in the database
      await update(referrerRef, {
        referredUsers: referredUsersCount
      });
    }
    return true;
  } catch (error) {
    console.error('Error updating referredUsers count:', error);
    return false;
  }
};

// Function to get the user by wallet address
const getUserByWalletAddress = async (walletAddress) => {
  try {
    const usersRef = ref(database, 'users');
    
    // Check if the "users" table exists
    return get(usersRef).then(async (snapshot) => {
      if (snapshot.exists()) {
        const q = query(usersRef, orderByChild('walletAddress'), equalTo(walletAddress));
        const snapshot = await get(q);
        
        
        if (snapshot.exists()) {
          const userData = Object.values(snapshot.val());
          const username = userData[0].username;
          return username;
        } else {
          return null;
        }
      } else {
        console.log('No users node');
        return null;
      }
    });
  } catch (error) {
    console.error('Error getting users by wallet address:', error);
    return false;
  }
};

// Function to update user points, multiplier, and levels
const updateUserPointsLevelAndMultiplier = async (uid, points) => {
  const user = await getUserData(uid);
  if (!user) return;

  const newXp = (user.xp || 0) + points;
  let newLevel = user.level || 1;

  // Determine new level based on XP
  for (const [level, xp] of Object.entries(levelXp)) {
    if (newXp >= xp) {
      newLevel = parseInt(level, 10);
    }
  }

  // Determine new multiplier based on level
  const newMultiplier = levelMultiplier[newLevel] || user.multiplier;

  // Format the points and multiplier to fixed precision
  const formattedTotalPoints = parseFloat(((user.totalPoints || 0) + points).toFixed(4));
  const formattedMonthlyPoints = parseFloat(((user.monthlyPoints || 0) + points).toFixed(4));
  const formattedXp = parseFloat(newXp.toFixed(4));
  const formattedMultiplier = parseFloat(newMultiplier.toFixed(4));

  await update(ref(database, `users/${uid}`), {
    totalPoints: formattedTotalPoints,
    monthlyPoints: formattedMonthlyPoints,
    xp: formattedXp,
    level: newLevel,
    multiplier: formattedMultiplier,
  });
};

// Create User Context
export const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [platformUser, setPlatformUser] = useState(null);
  const { address } = useGetAccountInfo();

  useEffect(() => {
    // Add Firebase Auth observer
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    // Clean-up
    return () => unsubscribe();
  }, []);

  //log-in method
  const login = async (username, walletAddress) => {    
    try {
      const email = `${username}@x-launcher.com`;
      const accountUsername = await getUserByWalletAddress(walletAddress);
      
      if (accountUsername === username) {
        await signInWithEmailAndPassword(auth, email, process.env.REACT_APP_DB_USER_PASSWORD).then(r=>{
          const user = auth.currentUser;
          fetchUserDetails(user.uid);
        });        
        return true;
      } else if (accountUsername === null) {
        return 'No xBid account with this wallet found';
      } else {
        return 'The username does not match the connected wallet';
      }
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  };

  //log-out method
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setPlatformUser(null);
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  };
  
  //sign-up method
  const signup = async (username, displayName, walletAddress, referrerCode) => {
    try {
      const email = `${username}@x-launcher.com`;
      const isWalletValid = await checkWalletAddress(walletAddress);
      if(isWalletValid){
        await createUserWithEmailAndPassword(auth, email, process.env.REACT_APP_DB_USER_PASSWORD).then(async r => {
          const user = auth.currentUser;
          const createdAt = Date.now().toString();
          await createUserProfile(user.uid, createdAt, {username: username, displayName: displayName, walletAddress: walletAddress}, referrerCode);
          // Update referredUsers count for the referrer
          await updateReferrerUsersCount(referrerCode);
          await createReferrerProfile(referrerCode, user.uid, createdAt, displayName, walletAddress);

          fetchUserDetails(user.uid);
          return true;
        });
      }else{
        return 'Wallet already in use';
      }
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  };

  // add data into users after sign-up method
  const createUserProfile = async (uid, createdAt, userData, referrerCode) => {
    try {
      const userProfileRef = ref(database, `users/${uid}`);
      let referralCode = generateReferralCode();

      // If the reffered code is present, then add it to the user profile
      let referrerId = '';
      if(referrerCode){
        referrerId = await getUserByReferralCode(referrerCode);
      }
  
      // Check if the generated referral code is already used by another user
      let isUniqueReferralCode = await checkReferralCode(referralCode);
  
      // Regenerate referral code until a unique one is found
      while (!isUniqueReferralCode) {
        referralCode = generateReferralCode();
        isUniqueReferralCode = await checkReferralCode(referralCode);
      }
  
      // Set user profile with unique referral code
      await set(userProfileRef, {
        username: userData.username,
        displayName: userData.displayName,
        walletAddress: userData.walletAddress,
        uid: uid,
        referralCode: referralCode,
        referrerId: referrerId,
        createdAt: createdAt,
        totalPoints: 0,
        monthlyPoints: 0,
        xp: 0,
        level: 1,
        quests: 0,
        referredUsers: 0,
        multiplier: 0.1,
        title: 'starter'
      });
    } catch (error) {
      console.error('Error creating user profile:', error);
      throw error;
    }
  };

  // add referral data into database
  const createReferrerProfile = async (referrerCode, referredId, createdAt, displayName, walletAddress) => {
    try {
      if(referrerCode){
        const referrerId = await getUserByReferralCode(referrerCode);
        const referralRef = ref(database, `referrals/${referrerId}/${referredId}`);
  
        // Add the current user to the referrer
        await set(referralRef, {
          displayName: displayName,
          walletAddress: walletAddress,
          uid: referredId,
          createdAt: createdAt,
          receivedPoints: 0,
          receivedMonthlyPoints: 0
        });
      }
    } catch (error) {
      console.error('Error creating referrer profile:', error);
      throw error;
    }
  }; 

  useEffect(() => {
    if (user) {
      // Fetch user details from the database
      fetchUserDetails(user.uid);
    }
  }, [user]);

  const fetchUserDetails = (uid) => {
    const userRef = ref(database, `users/${uid}`);
    // Listen for changes to the user details
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      setPlatformUser(userData); 
    });
  };

  // The function that will update points / xp / level for specified mvx transactions
  const updateReferralPoints = async (currentUserId, points) => {
    try {
      const user1 = await getUserData(currentUserId);
      if (!user1) return;
        
      // Update points, level, and multiplier for the current user
      await updateUserPointsLevelAndMultiplier(currentUserId, points);
  
      if (user1.referrerId) {
        const user2 = await getUserData(user1.referrerId);
        const user2Ref = await getRefUserData(user1.referrerId, currentUserId);
        if (!user2) return;
  
        const multiplier2 = user2.multiplier;
        const points2 = parseFloat((points * multiplier2).toFixed(4));
  
        // Update points, level, and multiplier for the referrer (user2)
        await updateUserPointsLevelAndMultiplier(user1.referrerId, points2);
  
        // Update referral points for the referrer (user2)
        await update(ref(database, `referrals/${user1.referrerId}/${currentUserId}`), {
          receivedPoints: parseFloat(((user2Ref.receivedPoints || 0) + points2).toFixed(4)),
          receivedMonthlyPoints: parseFloat(((user2Ref.receivedMonthlyPoints || 0) + points2).toFixed(4))
        });
  
        if (user2.referrerId) {
          const user3 = await getUserData(user2.referrerId);
          const user3Ref = await getRefUserData(user2.referrerId, user2.uid);
          if (!user3) return;
  
          const multiplier3 = user3.multiplier;
          const points3 = parseFloat((points2 * multiplier3).toFixed(4));
  
          // Update points, level, and multiplier for the referrer's referrer (user3)
          await updateUserPointsLevelAndMultiplier(user2.referrerId, points3);
  
          // Update referral points for the referrer's referrer (user3)
          await update(ref(database, `referrals/${user2.referrerId}/${user2.uid}`), {
            receivedPoints: parseFloat(((user3Ref.receivedPoints || 0) + points3).toFixed(4)),
            receivedMonthlyPoints: parseFloat(((user3Ref.receivedMonthlyPoints || 0) + points3).toFixed(4))
          });
  
          if (user3.referrerId) {
            const user4 = await getUserData(user3.referrerId);
            const user4Ref = await getRefUserData(user3.referrerId, user3.uid);
            if (!user4) return;
  
            const multiplier4 = user4.multiplier;
            const points4 = parseFloat((points3 * multiplier4).toFixed(4));
  
            // Update points, level, and multiplier for the referrer's referrer's referrer (user4)
            await updateUserPointsLevelAndMultiplier(user3.referrerId, points4);
  
            // Update referral points for the referrer's referrer's referrer (user4)
            await update(ref(database, `referrals/${user3.referrerId}/${user3.uid}`), {
              receivedPoints: parseFloat(((user4Ref.receivedPoints || 0) + points4).toFixed(4)),
              receivedMonthlyPoints: parseFloat(((user4Ref.receivedMonthlyPoints || 0) + points4).toFixed(4))
            });
          }
        }
      }
      return true;
    } catch (error) {
      console.error('Error updating referral points:', error);
      return false;
    }
  };

  // Function to get the user full details by wallet address
  const getFullUserByWalletAddress = async (walletAddress) => {
    try {
      const usersRef = ref(database, 'users');
      
      // Check if the "users" table exists
      return get(usersRef).then(async (snapshot) => {
        if (snapshot.exists()) {
          const q = query(usersRef, orderByChild('walletAddress'), equalTo(walletAddress));
          const snapshot = await get(q);
          
          
          if (snapshot.exists()) {
            const userData = Object.values(snapshot.val());
            return userData[0];
          } else {
            return null;
          }
        } else {
          console.log('No users node');
          return null;
        }
      });
    } catch (error) {
      console.error('Error getting users by wallet address:', error);
      return false;
    }
  };

  // Get all referred users for the logged in user
  const getReferredUsers = async (currentUserId) => {
    try {
      const referralsRef = ref(database, `referrals/${currentUserId}`);
      
      return new Promise((resolve, reject) => {
        onValue(referralsRef, (snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            console.log('No referred users found');
            resolve({});
          }
        }, (error) => {
          console.error('Error fetching referred users:', error);
          reject(error);
        });
      });
    } catch (error) {
      console.error('Error setting up listener for referred users:', error);
      throw error;
    }
  };


  const handleLogout = async () => {
    try {      
      await logout();
      toast.success('You have been logged out', {duration: 3000});
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    if (platformUser && address) {
      if(address.toString() !== platformUser.walletAddress){        
        handleLogout();
      }
    }
    const interval = window.setInterval(() => {
			if (platformUser && address) {
        if(address.toString() !== platformUser.walletAddress){        
          handleLogout();        
        } 
      }
		}, 2000);
		return () => window.clearInterval(interval);
	}, []);

  // Fetch top x users based on monthlyPoints
  const getTopUsersByMonthlyPoints = async (totalUsers) => {
    try {
      const usersRef = ref(database, 'users');
      const topUsersQuery = query(usersRef, orderByChild('monthlyPoints'), limitToLast(totalUsers));
  
      return new Promise((resolve, reject) => {
        onValue(topUsersQuery, (snapshot) => {
          if (snapshot.exists()) {
            const usersData = snapshot.val();
            const usersArray = Object.keys(usersData).map(key => usersData[key]);
  
            // Sort the usersArray in descending order of monthlyPoints
            usersArray.sort((a, b) => b.monthlyPoints - a.monthlyPoints);
  
            resolve(usersArray);
          } else {
            console.log('No users found');
            resolve([]);
          }
        }, (error) => {
          console.error('Error fetching top users:', error);
          reject(error);
        });
      });
    } catch (error) {
      console.error('Error setting up listener for top users:', error);
      throw error;
    }
  };

  // Fetch top x users based on totalPoints
  const getTopUsersByTotalPoints = async (totalUsers) => {
    try {
      const usersRef = ref(database, 'users');
      const topUsersQuery = query(usersRef, orderByChild('totalPoints'), limitToLast(totalUsers));
  
      return new Promise((resolve, reject) => {
        onValue(topUsersQuery, (snapshot) => {
          if (snapshot.exists()) {
            const usersData = snapshot.val();
            const usersArray = Object.keys(usersData).map(key => usersData[key]);
  
            // Sort the usersArray in descending order of totalPoints
            usersArray.sort((a, b) => b.totalPoints - a.totalPoints);
  
            resolve(usersArray);
          } else {
            console.log('No users found');
            resolve([]);
          }
        }, (error) => {
          console.error('Error fetching top users:', error);
          reject(error);
        });
      });
    } catch (error) {
      console.error('Error setting up listener for top users:', error);
      throw error;
    }
  };

  // Fetch top x users based on the referred users
  const getTopUsersByReferredUsers = async (totalUsers) => {
    try {
      const usersRef = ref(database, 'users');
      const topUsersQuery = query(usersRef, orderByChild('referredUsers'), limitToLast(totalUsers));
  
      return new Promise((resolve, reject) => {
        onValue(topUsersQuery, (snapshot) => {
          if (snapshot.exists()) {
            const usersData = snapshot.val();
            const usersArray = Object.keys(usersData).map(key => usersData[key]);
  
            // Sort the usersArray in descending order of referredUsers
            usersArray.sort((a, b) => b.referredUsers - a.referredUsers);
  
            resolve(usersArray);
          } else {
            console.log('No users found');
            resolve([]);
          }
        }, (error) => {
          console.error('Error fetching top users:', error);
          reject(error);
        });
      });
    } catch (error) {
      console.error('Error setting up listener for top users:', error);
      throw error;
    }
  };

  // reset monthly points for users and referrals nodes
  const resetMonthlyPoints = async () => {
    const db = getDatabase();
    
    try {
      // Reset monthly points for all users
      const usersRef = ref(db, 'users');
      const usersSnapshot = await get(usersRef);
  
      if (usersSnapshot.exists()) {
        const usersData = usersSnapshot.val();
        const updates = {};
  
        for (const userId in usersData) {
          updates[`users/${userId}/monthlyPoints`] = 0;
          updates[`users/${userId}/xp`] = 0;
          updates[`users/${userId}/level`] = 1;
          updates[`users/${userId}/multiplier`] = 0.1;
        }
  
        await update(ref(db), updates);
      }
  
      // Reset monthly points for all referrals
      const referralsRef = ref(db, 'referrals');
      const referralsSnapshot = await get(referralsRef);
  
      if (referralsSnapshot.exists()) {
        const referralsData = referralsSnapshot.val();
        const referralUpdates = {};
  
        for (const referrerId in referralsData) {
          const referredUsers = referralsData[referrerId];
          for (const referredId in referredUsers) {
            referralUpdates[`referrals/${referrerId}/${referredId}/receivedMonthlyPoints`] = 0;
          }
        }
  
        await update(ref(db), referralUpdates);
      }
  
      console.log('Monthly points reset successfully');
    } catch (error) {
      console.error('Error resetting monthly points:', error);
    }
  };

  // reset total points for users and referrals nodes
  const resetTotalPoints = async () => {
    const db = getDatabase();
    
    try {
      // Reset total points for all users
      const usersRef = ref(db, 'users');
      const usersSnapshot = await get(usersRef);
  
      if (usersSnapshot.exists()) {
        const usersData = usersSnapshot.val();
        const updates = {};
  
        for (const userId in usersData) {
          updates[`users/${userId}/totalPoints`] = 0;
        }
  
        await update(ref(db), updates);
      }
  
      // Reset total points for all referrals
      const referralsRef = ref(db, 'referrals');
      const referralsSnapshot = await get(referralsRef);
  
      if (referralsSnapshot.exists()) {
        const referralsData = referralsSnapshot.val();
        const referralUpdates = {};
  
        for (const referrerId in referralsData) {
          const referredUsers = referralsData[referrerId];
          for (const referredId in referredUsers) {
            referralUpdates[`referrals/${referrerId}/${referredId}/receivedPoints`] = 0;
          }
        }
  
        await update(ref(db), referralUpdates);
      }
  
      console.log('Total points reset successfully');
    } catch (error) {
      console.error('Error resetting Total points:', error);
    }
  };

  // get all the rewards for monthly points users ranking
  const getAllMonthlyRewards = async () => {
    const monthlyRewardsRef = ref(database, 'monthlyRewards');
  
    return new Promise((resolve, reject) => {
      onValue(monthlyRewardsRef, (snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          console.log('No rewards found');          
        }
      }, (error) => {
        console.error('Error getting all rewards:', error);
        reject(error);
      });
    });
  };

  // Function to update a reward for a specific level
  const updateMonthlyRewardByLevel = async (level, newReward) => {
    const db = getDatabase();
    const rewardRef = ref(db, `monthlyRewards/${level}`);

    try {
      await update(rewardRef, { rewards: newReward });
      return true;
    } catch (error) {
      console.error('Error updating reward by level:', error);
      return false;
    }
  };

  // get all the rewards for total points users ranking
  const getAllTotalRewards = async () => {
    const db = getDatabase();
    const totalRewardsRef = ref(db, 'totalRewards');
  
    return new Promise((resolve, reject) => {
      onValue(totalRewardsRef, (snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          console.log('No rewards found');
          resolve({});
        }
      }, (error) => {
        console.error('Error getting all rewards:', error);
        reject(error);
      });
    });
  };

  // Function to update a reward for a specific level
  const updateTotalRewardByLevel = async (level, newReward) => {
    const db = getDatabase();
    const rewardRef = ref(db, `totalRewards/${level}`);

    try {
      await update(rewardRef, { rewards: newReward });
      return true;
    } catch (error) {
      console.error('Error updating reward by level:', error);
      return false;
    }
  };

  // get all the rewards for referral users ranking
  const getAllReferralRewards = async () => {
    const db = getDatabase();
    const referralRewardsRef = ref(db, 'referralRewards');
  
    return new Promise((resolve, reject) => {
      onValue(referralRewardsRef, (snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          console.log('No rewards found');
          resolve({});
        }
      }, (error) => {
        console.error('Error getting all referral rewards:', error);
        reject(error);
      });
    });
  };

  // Function to update a reward for a specific level
  const updateReferralRewardByLevel = async (level, newReward) => {
    const db = getDatabase();
    const rewardRef = ref(db, `referralRewards/${level}`);

    try {
      await update(rewardRef, { rewards: newReward });
      return true;
    } catch (error) {
      console.error('Error updating reward by level:', error);
      return false;
    }
  };

  // get top users by the total received points from the reffered users
  const getTopUsersByReceivedMonthlyPoints = (totalUsers) => {
    return new Promise((resolve, reject) => {
      try {
        // Step 1: Fetch all users
        const usersRef = ref(database, 'users');

        onValue(usersRef, (usersSnapshot) => {
          if (!usersSnapshot.exists()) {
            console.log('No users found');
            resolve([]);
            return;
          }

          const usersData = usersSnapshot.val();
          const userIds = Object.keys(usersData);          

          // Step 2: Fetch all referrals for each user and aggregate the receivedMonthlyPoints
          const usersWithAggregatedPoints = [];
          let processedUsers = 0;

          userIds.forEach((uid) => {
            const referralsRef = ref(database, `referrals/${uid}`);
            
            onValue(referralsRef, (referralsSnapshot) => {              
              if (referralsSnapshot.exists()) {
                const referralsData = referralsSnapshot.val();
                const referralIds = Object.keys(referralsData);

                let totalReceivedMonthlyPoints = 0;

                referralIds.forEach((referralId) => {                
                  totalReceivedMonthlyPoints += referralsData[referralId].receivedMonthlyPoints || 0;
                });

                usersWithAggregatedPoints.push({
                  uid,
                  displayName: usersData[uid].displayName,
                  receivedMonthlyPoints: totalReceivedMonthlyPoints,
                  walletAddress: usersData[uid].walletAddress,
                  invitedUsers: referralIds.length
                });
              } else {
                usersWithAggregatedPoints.push({
                  uid,
                  displayName: usersData[uid].displayName,
                  receivedMonthlyPoints: 0,
                  walletAddress: usersData[uid].walletAddress,
                  invitedUsers: 0
                });
              }

              processedUsers++;
              
              // When all users are processed, resolve the promise
              if (processedUsers === userIds.length) {
                // Step 3: Sort the users based on aggregated receivedMonthlyPoints
                usersWithAggregatedPoints.sort((a, b) => b.receivedMonthlyPoints - a.receivedMonthlyPoints);

                // Step 4: Return the top users
                resolve(usersWithAggregatedPoints.slice(0, totalUsers));
              }
            }, (error) => {
              console.error('Error fetching referrals:', error);
              reject(error);
            });
          });
        }, (error) => {
          console.error('Error fetching users:', error);
          reject(error);
        });
      } catch (error) {
        console.error('Error setting up listener for top users:', error);
        reject(error);
      }
    });
  };

  return (
    <UserContext.Provider value={{ user, login, logout, signup, platformUser, updateReferralPoints, getReferredUsers, getTopUsersByMonthlyPoints, getTopUsersByTotalPoints, getTopUsersByReferredUsers, 
      resetMonthlyPoints, resetTotalPoints, getAllMonthlyRewards, updateMonthlyRewardByLevel, getAllTotalRewards, updateTotalRewardByLevel, getAllReferralRewards, updateReferralRewardByLevel,
      getTopUsersByReceivedMonthlyPoints, getFullUserByWalletAddress }}
    >
      {children}
    </UserContext.Provider>
  );
};