import { U64Value } from '@multiversx/sdk-core/out';
import axios from 'axios';
import {
  Provider,
  getSmartContractObj,
  resultsParser
} from 'contexts/scRequests';
import { AuctionInfo, DEFAULT } from 'store/types/auctionInfo';
import * as config from '../config';
import { AuctionInfoAndUrl, DEFAULT_URL } from 'store/types/auctionInfoAndUrl';

export const getAuctionInfo = async (
  auctionId: number
): Promise<AuctionInfoAndUrl> => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getAuctionInfo([
    new U64Value(auctionId)
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();

    const parsed = convertApiResponseUrl(value);
    if (parsed !== null) {
      return parsed;
    }
  } else {
    // console.log('getAuctionInfo failed', auctionId, parsedResponse);
  }

  return DEFAULT_URL;
};

export const getAuctionHistory = async (): Promise<AuctionInfoAndUrl[]> => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getAuctionHistory();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    
    const ret = [];
    for (let i = 0; i < value.length; i++) {
      const crtVal = value[i];
      const parsed = convertApiResponseUrl(crtVal);
      if (parsed === null) {
        continue;
      }
      ret.push(parsed);
    }
    return ret;
  }

  return [DEFAULT_URL];
};

const convertApiResponse = (
  scApiResponse: any,
  apiResponse: any
): AuctionInfoAndUrl | null => {
  if (scApiResponse === undefined) {
    return null;
  }

  return {
    asset: {
      amount: scApiResponse.asset.amount.toNumber(),
      token_identifier: scApiResponse.asset.token_identifier,
      nonce: scApiResponse.asset.token_nonce.toNumber(),
      url: apiResponse?.data?.url,
      name: apiResponse?.data?.name,
      description: apiResponse?.data?.metadata?.description,
      full_identifier: apiResponse?.data?.identifier,
    },
    starting_price: scApiResponse.starting_price.toString(),
    last_bid_price: scApiResponse.last_bid_price.toString(),
    last_bid_timestamp: scApiResponse.last_bid_timestamp.toNumber(),
    last_bid_block: scApiResponse.last_bid_block.toString(),
    start_time: scApiResponse.start_time.toNumber(),
    end_time: scApiResponse.end_time.toNumber(),
    last_bidder: scApiResponse.last_bidder.hex(),
    url: scApiResponse.url.toString(),
    name: scApiResponse.name.toString(),
    claimed: scApiResponse.claimed.toNumber(),
    esdt_token_id: scApiResponse.esdt_token_id,
    esdt_token_name: scApiResponse.esdt_token_name ? scApiResponse.esdt_token_name.toString() : ''
  };
};

const convertApiResponseUrl = (
  scApiResponse: any,
): AuctionInfoAndUrl | null => {
  if (scApiResponse === undefined) {
    return null;
  }

  

  return {
    asset: {
      amount: scApiResponse.asset.amount.toNumber(),
      token_identifier: scApiResponse.asset.token_identifier,
      nonce: scApiResponse.asset.token_nonce.toNumber(),
      url: 'apiResponse.data.url',
      name: scApiResponse.name.toString(),
      description: 'apiResponse.data.metadata.description',
      full_identifier: 'apiResponse.data.identifier',

    },
    starting_price: scApiResponse.starting_price.toString(),
    last_bid_price: scApiResponse.last_bid_price.toString(),
    last_bid_timestamp: scApiResponse.last_bid_timestamp.toNumber(),
    last_bid_block: scApiResponse.last_bid_block.toString(),
    start_time: scApiResponse.start_time.toNumber(),
    end_time: scApiResponse.end_time.toNumber(),
    last_bidder: scApiResponse.last_bidder.hex(),
    url: scApiResponse.url[0].toString(),
    name: scApiResponse.name.toString(),
    claimed: scApiResponse.claimed.toNumber(),
    esdt_token_id: scApiResponse?.esdt_token_id,
    esdt_token_name: scApiResponse?.esdt_token_name ? scApiResponse.esdt_token_name.toString() : ''
  };
};

const getNftInfo = async (tokenId: string, nonce: number) => {
  // console.log('fetch nft info');
  try {
    const { data } = await axios.get(
      `${config.network.apiAddress}/nfts/${tokenId}-${decimalToHex(nonce)}`,
      {
        timeout: 10000
      }
    );
    // https://api.multiversx.com/nfts/MLE-a05e4e-0a
    return {
      data: data,
      success: data !== undefined
    };
  } catch (err) {
    return {
      success: false
    };
  }


};

export function decimalToHex(d: any) {
  const hex = Number(d).toString(16);
  const paddedHex = hex.length % 2 === 0 ? hex : '0' + hex; // Add leading zero if necessary
  return paddedHex;
}

export const getAuctionTokenId = async (
  auctionId: number
): Promise<string> => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getEsdtAuctionTokenId([
    new U64Value(auctionId)
  ]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();    
    return value;
  } else {
    console.log('getAuctionTokenId failed');
  }

  return '';
};

export const getAuctionHistoryFromId = async (
  auctionId: number
): Promise<any> => {
  let value = 1;
  if(auctionId > 10){
    value = auctionId - 10;
  }
  
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getAuctionHistoryFromId([
    new U64Value(value)
  ]);
  
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  ); 
  
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    
    const ret = [];
    for (let i = 0; i < value.length; i++) {
      const crtVal = value[i];
      const parsed = convertApiResponseUrl(crtVal);
      if (parsed === null) {
        continue;
      }
      ret.push(parsed);
    }
    return ret;
  }
 else {
    console.log('getAuctionHistoryFromId failed');
  }

  return '';
};


