import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styles from '../../lottery.module.scss';

export interface TicketCardNumberProps {
    number: number;
}

export const TicketCardNumber: React.FC<TicketCardNumberProps> = (props: TicketCardNumberProps) => {
    return (
        <div className={styles.numberRight}>
            <div className="d-flex align-items-center justify-content-center h-100 font-bold">
                <p className="m-0 text-white">{props.number}</p>
            </div>
        </div>
    );
};
