import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network, xBidToken } from 'config';
import { getSmartContractObj } from 'contexts/scMysteryKeysRequests';
import { FixedLengthArray } from 'typesS';
import { Address, Transaction, TransactionWatcher, U64Value, TokenTransfer, BigUIntValue } from '@multiversx/sdk-core/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './useSendAndSign';
import BigNumber from 'bignumber.js';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';

const watcher = new TransactionWatcher(new ApiNetworkProvider(network.apiAddress), { patienceMilliseconds: 8000 });

const sendAndSignTransactionsWrapped = async (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
): Promise<{
    success: boolean;
    error: string;
    sessionId: string | null;
}> => {
    const result = await sendAndSignTransactions(transactions, displayInfo);
    await watcher.awaitCompleted(transactions[0]);
    return result;
};

export const useMysteryBuyKey = (keysAmount: number) => {
    const { account } = useGetAccountInfo();

    const displayInfo = {
        processingMessage: 'Processing buy key transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Key bought successfully'
    };

    const buyKey = async () => {
        const contract = await getSmartContractObj();

        const keys = new BigNumber(100 * keysAmount);
        const denomination = new BigNumber(10).pow(18);

        const price = 100;
        const interaction = contract.methodsExplicit.buyItem([new U64Value(1), new BigUIntValue(keysAmount)]);

        const identifier = xBidToken;
        const numDecimals = 18;

        const transfer = TokenTransfer.fungibleFromAmount(identifier, (keysAmount * price).toLocaleString('en-US', { useGrouping: false }), numDecimals);
        const transaction = interaction
            .withNonce(account.nonce)
            .withSingleESDTTransfer(transfer)
            .withGasLimit(10_000_000)
            .withChainID(network.chainId)            
            .buildTransaction();
        const sessionId = await sendAndSignTransactionsWrapped(
            [transaction],
            displayInfo
        );
        return sessionId;
    };

    return buyKey;
};
