import React, {useContext, useState} from 'react';
import { Web3Context } from 'contexts';
// import { Carousel, CarouselItem } from 'react-bootstrap';
import { PastAuction } from './PastAuction';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './style.css';
import { LoadingScreen } from 'components/LoadingScreen';
import styles from '../../pages/TestRoute/testRoute.module.scss';
import { getAuctionHistoryFromId } from 'api/getAuctionInfo';

export const HistoryCarousel = () => {
  const context = useContext(Web3Context);

  const [auctionHistory, setAuctionHistory] = useState([{
    asset: undefined,
    starting_price: '0',
    last_bid_price: '0',
    last_bid_timestamp: 0,
    last_bid_block: 0,
    start_time: 0,
    end_time: 0,
    last_bidder: '',
    url: [],
    name: '',
    claimed: 0,
    esdt_token_id: '',
    esdt_token_name: ''
  }]);
  
  // get the auction token id
  const getAuctionHistoryData = async () => {
    if(context.currentAuctionId){
      const newHistoryData = await getAuctionHistoryFromId(context.currentAuctionId);
      if(newHistoryData){
        setAuctionHistory(newHistoryData);
      }
    }
  };

  React.useEffect(() => {
		getAuctionHistoryData();    
		const interval = window.setInterval(() => {
			getAuctionHistoryData();
		}, 3000);
		return () => window.clearInterval(interval);
	}, [context]);  

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return auctionHistory === null ? (
    <LoadingScreen />
  ) : (
    <>
      {auctionHistory.length === 0 ? (
        // <h1>No past auctions to show</h1>
        <> </>
      ) : (
        <div className='carousel-wrapper'>
          <div className={styles.header}> Auction History </div>
          <div className='mb-5'></div>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite
            showDots={false}
            keyBoardControl={false}
            // arrows={false}
            autoPlaySpeed={3000}
          >
            {auctionHistory?.map((auction, i) => (
              <PastAuction auction={auction} key={`auction-history-${i}`} auctionId={i} />
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};
