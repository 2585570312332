import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthRedirectWrapper, ExtensionLoginButton, LedgerLoginButton, OperaWalletLoginButton, WalletConnectLoginButton, WebWalletLoginButton } from 'components';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import styles from './home.module.scss';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiversXIcon from '../../assets/img/multiversx-symbol-black.svg';
import LedgerIcon from '../../assets/img/Ledger-symbol-black.svg';
import { ReactComponent as XBidLogo } from '../../assets/img/logo.svg';
import { Footer } from 'components/Layout/Footer';
import { UserContext } from 'contexts';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import {logout as walletLogout} from 'helpers';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import toast, {Toaster} from 'react-hot-toast';

const HomePage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [referralCode, setReferralCode] = useState(urlParams.get('referralCode'));
  const visibleLogin = urlParams.get('isLoginVisible') ? false : true;
  const queryParams = window.location.search;

  const handleWalletLogout = () => {
		walletLogout(`${window.location.origin}/unlock${queryParams}`);
	};

  const commonProps = {
    callbackRoute: '/unlock' + queryParams,
    nativeAuth: true // optional
  };

  // firebase login / signup
  const { address } = useGetAccountInfo();
  const { login, signup, platformUser, logout } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [isLoginVisible, setIsLoginVisible] = useState(visibleLogin);
  const [showUsername, setShowUsername] = useState(false);

  const toggleUsernameVisibility = () => {
    setShowUsername(!showUsername);
  };

  const handleSignup = async () => {
    try {      
      if(!address){
        toast.error('Error: Wallet not connected', {duration: 3000});
        return;
      }
      const result = await signup(username.toLowerCase(), displayName ? displayName : 'xBid Member', address.toString(), referralCode ? referralCode : null);
      if (result === true) {
        toast.success('Account created, you are now logged in', {duration: 3000});
      }  
      if (result === 'Wallet already in use') {
        toast.error('Error: Wallet already in use', {duration: 3000});
      }
    } catch (error: any) {
      if(error.code == 'auth/email-already-in-use'){
        toast.error('Error: Username already in use', {duration: 3000});
      }else{
        toast.error('Error: Account not created', {duration: 3000});
      }
    }
  };

  const handleLogin = async () => {
    try {
      if(!address){
        toast.error('Error: Wallet not connected', {duration: 3000});
        return;
      }

      const result = await login(username.toLowerCase(), address.toString());
      if (result === 'No xBid account with this wallet found') {
        toast.error('No xBid account with this wallet found', {duration: 3000});
      }
      if (result === 'The username does not match the connected wallet') {
        toast.error('The username does not match the connected wallet', {duration: 3000});
      }
      if (result === true) {
        toast.success('You have been successfully logged in', {duration: 3000});
      }
    } catch (error: any) {
      if(error.code == 'auth/invalid-credential'){
        toast.error('Error: Username not exists', {duration: 3000});
      }else if (error.code == 'auth/invalid-email'){
        toast.error('Error: Invalid username', {duration: 3000});
      } else{
        toast.error('Error: Login failed', {duration: 3000});
      }
    }
  };

  const handleLogout = async () => {
    try {      
      await logout();
      toast.success('You have been logged out', {duration: 3000});
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    if (platformUser && address) {
      if(address.toString() !== platformUser.walletAddress){        
        handleLogout();        
      } 
    }
    const interval = window.setInterval(() => {
			if (platformUser && address) {
        if(address.toString() !== platformUser.walletAddress){        
          handleLogout();        
        } 
      }
		}, 2000);
		return () => window.clearInterval(interval);
	}, []);


  return (
    <>
      <div className={styles.container}>        
        <article className={styles.loginBox}>
          <XBidLogo className={styles.logo} />
          <h1 className={styles.title}>Welcome to xBid</h1>
          <p className={styles.text}>
            Auction house dApp, login using your MultiversX wallet
          </p>

          {!address && (
            <>
              <div className={styles.bar}>
                <span className={`${styles.barImg}`} />
                <div className={`${styles.barTitle}`}>
                  Wallet Connect
                </div>
                <span className={`${styles.barImg}`} />
              </div>

              <div className={styles.btns}>
                <ExtensionLoginButton
                  buttonClassName={styles.btn}
                  {...commonProps}
                >
                  <img
                      alt=""
                      height={0}
                      width={0}
                      src={MultiversXIcon}
                      className={styles.btnLogo}
                  />
                  <div className={styles.btnText}>
                      MultiversX DeFi Wallet
                  </div>
                  <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
                </ExtensionLoginButton>

                <WebWalletLoginButton
                  buttonClassName={styles.btn}
                  {...commonProps}
                >
                  <img
                      alt=""
                      height={0}
                      width={0}
                      src={MultiversXIcon}
                      className={styles.btnLogo}
                  />
                  <div className={styles.btnText}>
                      MultiversX Web Wallet
                  </div>

                  <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
                </WebWalletLoginButton>

                <LedgerLoginButton
                  buttonClassName={styles.btn}
                  {...commonProps}
                >
                  <img
                      alt=""
                      height={0}
                      width={0}
                      src={LedgerIcon}
                      className={styles.btnLogo}
                  />
                  <div className={styles.btnText}>
                      Ledger
                  </div>

                  <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
                </LedgerLoginButton>

                <WalletConnectLoginButton
                  buttonClassName={styles.btn}
                  {...commonProps}
                >
                  <img
                      alt=""
                      height={0}
                      width={0}
                      src={MultiversXIcon}
                      className={styles.btnLogo}
                  />
                  <div className={styles.btnText}>
                      xPortal App
                  </div>

                  <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
                </WalletConnectLoginButton>
              </div>

              {platformUser && (
                <>
                  <div className={styles.bar}>
                    <span className={`${styles.barImg}`} />
                    <div className={`${styles.barTitle}`}>
                      xBid Account Connected
                    </div>
                    <span className={`${styles.barImg}`} />
                  </div>

                  <div>
                    <div className='d-flex justify-content-between' style={{marginTop: '-20px', marginBottom: '10px'}}>
                      <p>Account Wallet</p>
                      <p className='text-info'>{platformUser.walletAddress.slice(0, 12)} ... {platformUser.walletAddress.slice(50, 62)}</p>                    
                    </div>  
                    <div className='d-flex justify-content-between align-items-center' style={{marginTop: '-20px', marginBottom: '10px'}}>
                      <p>Username</p>
                      <div>
                      <p className='text-info' style={{display: 'inline-block'}}>{showUsername ? platformUser.username : '••••••••'}</p>
                      <IconButton onClick={toggleUsernameVisibility}>
                        {showUsername ? <VisibilityOff style={{color: 'lightblue'}} />: <Visibility style={{color: 'lightblue'}} />}
                      </IconButton>
                      </div>
                    </div>
                    
                    <Button
                      onClick={handleLogout}
                      fullWidth
                      className={`${styles.buttonDark} text-white`}
                    >
                      Disconnect Account
                    </Button>
                  </div>
                </>
              )}

            </>
          )}

          {address && (
            <div>
              <div className={styles.bar}>
                <span className={`${styles.barImg}`} />
                <div className={`${styles.barTitle}`}>
                  Wallet Connected
                </div>
                <span className={`${styles.barImg}`} />
              </div>

              <div>
                <div className='d-flex justify-content-between' style={{marginTop: '-20px', marginBottom: '10px'}}>
                  <p>Wallet Address:</p>
                  <p className='text-info'>{address.slice(0, 18)} ... {address.slice(44, 62)}</p>                    
                </div> 
                <Button                  
                  onClick={handleWalletLogout}
                  fullWidth
                  className={`${styles.buttonDark} text-white mb-2`}
                  style={{marginTop: '-10px'}}
                >
                  Disconnect wallet
                </Button>
              </div>


              <div className={styles.bar}>
                <span className={`${styles.barImg}`} />
                <div className={`${styles.barTitle}`}>
                  xBid Account Login
                </div>
                <span className={`${styles.barImg}`} />
              </div>

              {isLoginVisible && (
                <div className={styles.btns}>              
                  <div>
                    <TextField 
                      label="Username" 
                      variant="outlined"
                      color="info"
                      fullWidth
                      size='small'
                      value={username}                
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9a-zA-Z.]/.test(event.key)) {
                            event.preventDefault();
                        }
                      }}
                      InputProps={{
                        style: {color: 'white', fontSize: '14px', background: '#1b1e43'}
                      }}
                      InputLabelProps={{
                          style: {color: 'white', fontSize: '15px'}
                      }}
                      className="text-white b-r-xs"
                    />
                    <Button
                      onClick={handleLogin}
                      fullWidth
                      className={`${styles.buttonDark} mt-2 text-white mb-3`}
                    >
                      Login
                    </Button>                    
                    <p className='text-center'>
                      New here? 
                      <span className='ms-1' onClick={() => setIsLoginVisible(false)} style={{cursor: 'pointer', textDecoration: 'underline'}}>Create an xBid account</span>
                    </p>
                  </div>              
                </div>
              )}

              {!isLoginVisible && (
                <div className={styles.btns}>              
                  <div>
                    <TextField 
                      label="Username" 
                      variant="outlined"
                      color="info"
                      fullWidth
                      size='small'
                      helperText='* Required, never share your username with other users'
                      value={username}                
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9a-zA-Z.]/.test(event.key)) {
                            event.preventDefault();
                        }
                      }}
                      InputProps={{
                        style: {color: 'white', fontSize: '14px', background: '#1b1e43'}
                      }}
                      InputLabelProps={{
                          style: {color: 'white', fontSize: '15px'}
                      }}
                      FormHelperTextProps={{ 
                        style: { color: 'lightgray'}
                      }}
                      className="text-white b-r-xs mb-2"
                    />
                    <TextField 
                      label="Display Name" 
                      variant="outlined"
                      color="info"
                      fullWidth
                      size='small'
                      helperText='* Optional, how other users can see you, set as "xBid Member" by default'
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9a-zA-Z. ]/.test(event.key)) {
                            event.preventDefault();
                        }
                      }}
                      InputProps={{
                        style: {color: 'white', fontSize: '14px', background: '#1b1e43'}
                      }}
                      InputLabelProps={{
                          style: {color: 'white', fontSize: '15px'}
                      }}
                      FormHelperTextProps={{ 
                        style: { color: 'lightgray' } 
                      }}
                      className="text-white b-r-xs mt-2 mb-2"
                    />
                    <TextField 
                      label="Referral Code" 
                      variant="outlined"
                      color="info"
                      fullWidth
                      size='small'
                      helperText='* Optional, used if you have been invited by another user'
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                      onKeyPress={(event) => {
                        if (!/[0-9a-zA-Z]/.test(event.key)) {
                            event.preventDefault();
                        }
                      }}
                      InputProps={{
                        style: {color: 'white', fontSize: '14px', background: '#1b1e43'}
                      }}
                      InputLabelProps={{
                          style: {color: 'white', fontSize: '15px'}
                      }}
                      FormHelperTextProps={{ 
                        style: { color: 'lightgray' } 
                      }}
                      className="text-white b-r-xs mt-2 mb-2"
                    />
                    <Button                  
                      onClick={handleSignup}
                      fullWidth
                      className={`${styles.buttonDark} mt-2 text-white mb-3`}
                    >
                      Create Account
                    </Button>
                    <p className='text-center'>
                      Already have an account?
                      <span className='ms-1' onClick={() => setIsLoginVisible(true)} style={{cursor: 'pointer', textDecoration: 'underline'}}>Sign in</span>
                    </p>
                  </div>              
                </div>
              )}
            </div>
          )}          
        </article>
      </div>
      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </>
  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
