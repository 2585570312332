import React, { useContext } from 'react';
import { TypedValue, U64Value, TokenIdentifierValue } from '@multiversx/sdk-core/out';
import { ITokenPayment, IAddress, TokenPayment, Address } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { getSmartContractObj } from 'contexts/scLotteryRequests';
import * as config from '../../../config';
import { BigUIntValue } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';

export const useChangeRewardTokenId = (newRewardTokenId: string) => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrapped} = useContext(Web3LotteryContext);

    const changeTokenId = async () => {

            const contract = await getSmartContractObj();
            const interaction = contract.methodsExplicit.setTokenIdPerLottery([new TokenIdentifierValue(newRewardTokenId)]);
            const displayInfo = {
                processingMessage: 'Processing change reward token id transaction',
                errorMessage: 'An error has occurred while processing the transaction',
                successMessage: 'Change successful, starting with next lottery'
            };
            const { sessionId, error } = await sendAndSignTransactionsWrapped(
                [
                    interaction
                        .withNonce(account.nonce)
                        .withValue(0)
                        .withGasLimit(5_000_000)
                        .withChainID(config.network.chainId)
                        .buildTransaction()
                ],
                displayInfo
            );
            // setSessionId(sessionId);
            return sessionId;
        };

    return changeTokenId;
};



// const changeTokenId = async () => {

    //     const contract = await getSmartContractObj();
    //     const interaction = contract.methodsExplicit.setTokenId([new TokenIdentifierValue(newRewardTokenId)]);
    //     const displayInfo = {
    //         processingMessage: 'Processing change reward token id transaction',
    //         errorMessage: 'An error has occurred while processing the transaction',
    //         successMessage: 'Change successful'
    //     };
    //     const { sessionId, error } = await sendAndSignTransactionsWrapped(
    //         [
    //             interaction
    //                 .withNonce(account.nonce)
    //                 .withValue(0)
    //                 .withGasLimit(5_000_000)
    //                 .withChainID(config.network.chainId)
    //                 .buildTransaction()
    //         ],
    //         displayInfo
    //     );
    //     // setSessionId(sessionId);
    //     return sessionId;
    // };