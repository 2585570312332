import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scRequests';
import React, { useContext } from 'react';
import { sendAndSignTransactions } from './useTest';
import { Web3Context } from 'contexts/Web3Context';


export const useEnableContract = () => {
  const { account } = useGetAccountInfo();
  const displayInfo = {
    processingMessage: 'Processing enable contract transaction',
    errorMessage: 'An error has occurred while processing the transaction',
    successMessage: 'Enable contract successful'
  };
  const { sendAndSignTransactionsWrapped } = useContext(Web3Context);

  const enableContract = async () => {
    const contract = await getSmartContractObj();
    const interaction = contract.methodsExplicit.enableSC();
    const { sessionId, error } = await sendAndSignTransactionsWrapped(
      [
        interaction
          .withNonce(account.nonce)
          .withGasLimit(5_000_000)
          .withChainID(network.chainId)
          .buildTransaction()
      ],

      displayInfo,
    );

    // setSessionId(sessionId);

    return sessionId;
  };

  // console.log(enableContract);
  return enableContract;
};
