import React, { useContext } from 'react';
import { TypedValue, U64Value } from '@multiversx/sdk-core/out';
import { ITokenPayment, IAddress, TokenPayment, Address } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { getSmartContractObj } from 'contexts/scLotteryRequests';
import * as config from '../../../config';
import { BigUIntValue } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';

export const useChangeMaxNumber = (newMaxNumber: number) => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrapped } = useContext(Web3LotteryContext);

    const changeMaxNumber = async () => {

        const contract = await getSmartContractObj();
        const interaction = contract.methodsExplicit.setMaxNumber([new U64Value(newMaxNumber)]);
        const displayInfo = {
            processingMessage: 'Processing change max number transaction',
            errorMessage: 'An error has occurred while processing the transaction',
            successMessage: 'Change successful'
        };
        const { sessionId, error } = await sendAndSignTransactionsWrapped(
            [
                interaction
                    .withNonce(account.nonce)
                    .withValue(0)
                    .withGasLimit(55_000_000) //55 mil should cover approx until 150
                    .withChainID(config.network.chainId)
                    .buildTransaction()
            ],
            displayInfo
        );
        // setSessionId(sessionId);
        return sessionId;
    };

    return changeMaxNumber;
};

