import React, { useState } from 'react';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import { LoadingScreen } from 'components/LoadingScreen';
import { useMysteryAddReward } from 'contexts/hooks/transactions/useMysteryAddReward';

export const PrizesContractDetails = () => {
  const [type, setType] = useState('ESDT');
  const [identifier, setIdentifier] = useState('');
  const [nonce, setNonce] = useState(0);
  const [totalRewardAmount, setTotalRewardAmount] = useState(0);
  const [prizesNumber, setPrizesNumber] = useState(0);

  const handleTypeChange = (event: any) => {
    setType(event.target.value);
  };

  const handleIdentifierChange = (event: any) => {
    setIdentifier(event.target.value);
  };

  const handleNonceChange = (event: any) => {
    setNonce(event.target.value);
  };

  const handleTotalRewardAmountChange = (event: any) => {
    setTotalRewardAmount(event.target.value);
  };

  const handlePrizesNumberChange = (event: any) => {
    setPrizesNumber(event.target.value);
  };

  return (
    <>
      <div className='card dapp-core-component__transactionsTable-styles__transactions-table mb-2'>
        <div className='card-header'>Mystery Box - Prizes Contract Config</div>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <strong>Contract</strong>
            <span>{config.mysteryPrizesContractAddress}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-baseline'>
            <strong>Reward Type</strong>
            <select id="typeSelect" value={type} onChange={handleTypeChange}>
              <option value="ESDT">ESDT</option>
              <option value="SFT">SFT</option>
              <option value="NFT">NFT</option>
            </select>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Reward Identifier</strong>
            <span><input type="text" className="form-control" id="input1" value={identifier} onChange={e => handleIdentifierChange(e)} /></span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Reward Nonce</strong>
            <span><input type="number" className="form-control" id="input2" value={nonce} onChange={e => handleNonceChange(e)} /></span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Total Reward Amount(estd and sft only)</strong>
            <span><input type="number" className="form-control" id="input3" value={totalRewardAmount} onChange={e => handleTotalRewardAmountChange(e)} /></span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Prizes Number</strong>
            <span><input type="number" className="form-control" id="input3" value={prizesNumber} onChange={e => handlePrizesNumberChange(e)} /></span>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <button className='btn btn-primary' onClick={useMysteryAddReward(type, identifier, nonce, totalRewardAmount, prizesNumber)}>
            Add Reward
          </button>
        </div>
      </div>      
    </>
  );
};
