import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import {
  AxiosInterceptorContext, // using this is optional
  DappProvider,
  Layout
} from 'components';
import {
  TransactionsToastList,
  NotificationModal,
  SignTransactionsModals
} from 'components';
import {
  apiTimeout,
  walletConnectV2ProjectId,
  sampleAuthenticatedDomains
} from 'config';
import { Web3Provider } from 'contexts';
import { Web3LotteryProvider } from 'contexts';
import { UserProvider } from 'contexts';
import { PageNotFound, Unlock } from 'pages';
import { routeNames } from 'routes';
import { routes } from 'routes';
import { EnvironmentsEnum } from 'types';
import { Provider } from 'react-redux';
import store from 'store';
// test deploy comment

export const App = () => {
  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor
        authenticatedDomanis={sampleAuthenticatedDomains}
      >
        <Router>
          <DappProvider
            environment={EnvironmentsEnum.mainnet}
            customNetworkConfig={{
              apiTimeout: apiTimeout,
              walletConnectV2ProjectId: walletConnectV2ProjectId
            }}
            dappConfig={{shouldUseWebViewProvider: true, logoutRoute: '/dashboard'}}
          >
            <UserProvider>
              <Provider store={store}>
                <AxiosInterceptorContext.Listener />
                <TransactionsToastList />
                <NotificationModal />
                <SignTransactionsModals className='custom-class-for-modals' />
                <Routes>
                  {routes.map((route, index) => {
                    const ComponentToRender = route.component;

                    if (route.path === routeNames.testRoute || route.path === routeNames.adminAuction) {
                      return (
                        <Route
                          key={'route-key-' + index}
                          path={route.path}
                          element={
                            <Web3Provider>
                              <ComponentToRender />
                            </Web3Provider>
                          }
                        />
                      );
                    } else if (route.path === routeNames.lottery || route.path === routeNames.adminLottery) {
                      return (
                        <Route
                          key={'route-key-' + index}
                          path={route.path}
                          element={
                            <Web3LotteryProvider>
                              <ComponentToRender />
                            </Web3LotteryProvider>
                          }
                        />
                      );
                    } else {
                      return (
                        <Route
                          key={'route-key-' + index}
                          path={route.path}
                          element={<ComponentToRender />}
                        />
                      );
                    }
                  })}
                  <Route path='*' element={<PageNotFound />} />
                </Routes>
              </Provider>
            </UserProvider>
          </DappProvider>
        </Router>
      </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  );
};
