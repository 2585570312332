import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Ticket } from 'Ticket';
import { TicketCardNumber } from '../TicketCard/TicketCardNumber';
import BigNumber from 'bignumber.js';
import styles from '../../lottery.module.scss';
import './index.css';
import { Row, Col} from 'react-bootstrap';


export const WinningNumbers: React.FC<{ numbers: number[] }> = ({
  numbers
}) => {
  return (    
        <Row className={styles.numbersBox} style={{justifyContent: 'center'}}>
          {numbers?.map((number, index) => (
            <Col xs={3} lg={1} key={number} className='mt-3'>
              <div className={styles.numbers2}>
                <p className={`text-white font-bold ${getCorrectIndex(index)}`} >{number}</p>
              </div>
            </Col>
          ))}
      </Row>
  );
};

export const getCorrectIndex = (currentIndex: number) => {

  const sphereIndex = currentIndex % 6;
  const sphereClass = `back${sphereIndex + 1}`;
  return sphereClass;
};

