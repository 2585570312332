// MysteryBox.jsx
import React, { useEffect, useState, useContext } from 'react';
import { Layout } from 'components/Layout/Layout';
import styles from './raffle.module.scss';
import './raffle.css';
import { Row, Col } from 'react-bootstrap';
import { Button} from '@mui/material';
import { useGetAccountInfo, useGetActiveTransactionsStatus, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import * as config from '../../config';
import BigNumber from 'bignumber.js';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import * as scRequests from '../../contexts/scRaffleRequests';
import { useRaffleBuyTickets } from 'contexts/hooks/transactions/useRaffleBuyTickets';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';
import Timer from './components/Timer';
import MiniTimer from './components/MiniTimer';
import ProgressBar from './components/ProgressBar';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getDatabase, ref as databaseRef, set, remove, get } from 'firebase/database';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckIcon from '@mui/icons-material/Check';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const componentsProps = {
	tooltip: {
		sx: {
			maxWidth: '200px',
			backgroundColor: 'black',
			color: 'white',
			fontSize: '14px',
			fontWeight: '400',
			textAlign: 'center',
			borderRadius: '10px',
			padding: '10px',
			top: '-10px'
		},
	},
	arrow: {
		sx: {
			color: 'black',
		},
	},
	TransitionComponent: Fade,
};

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const formatOnlyDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const formatOnlyHour = (timestamp: number) => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit'
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const getAllRafflePrizes = async (): Promise<any[]> => {
  const database = getDatabase();

  try {
    const rafflePrizesRef = databaseRef(database, 'rafflePrizes');
    const snapshot = await get(rafflePrizesRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      const prizesArray = Object.keys(data).map(key => data[key]);
      return prizesArray;
    } else {
      console.log('No raffle prizes found');
      return [];
    }
  } catch (error) {
    console.error('Error fetching raffle prizes:', error);
    return [];
  }
};

interface Prize {
  type: 'tokens' | 'sfts';
  identifier: string;
  amount: number;
  nonce: number;
  imageUrl: string;
}

export const Raffle = () => {
  const { platformUser, updateReferralPoints } = useContext(UserContext);
  const { address } = useGetAccountInfo();
  const { success } = useGetActiveTransactionsStatus();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const currentTimestamp = new Date().getTime();
  const currentDateTimestamp = Math.floor(currentTimestamp / 1000);

  // current raffle details
  const [lastRaffleId, setLastRaffleId] = useState(0);
  const [payment, setPayment] = useState({token_identifier: '', token_nonce: 0, amount: 0});
  const [currentRaffle, setCurrentRaffle] = useState({id: 0, slots: 0, start_time: 0, end_time: 0});
  const [currentPrizes, setCurrentPrizes] = useState([{}]);
  const [currentEntries, setCurrentEntries] = useState(0);
  const [myCurrentEntries, setMyCurrentEntries] = useState(0);
  const [currentWinners, setCurrentWinners] = useState([{}]);
  const [isWinner, setIsWinner] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const getRaffles = async () => {
    await scRequests.getTicketPayment().then((p: any) => {
      if(p){
        setPayment(p);
        if(address){
          getTicketsBalance(p.token_identifier, parseInt(p.token_nonce));
        }
        getTicketsInfo(p.token_identifier, parseInt(p.token_nonce), p.amount);
      }
    });

    await scRequests.getRaffles().then(async (r: any) => {
      if(r.length > 0){
        setLastRaffleId(r.slice(-1));

        const raffleData = await scRequests.getRaffleInfo(r.slice(-1));
        if(raffleData){
          setCurrentRaffle(raffleData);
          if(raffleData?.end_time < currentDateTimestamp){
            setIsEnded(true);
          }
        }

        const prizesData = await scRequests.getRafflePrizes(r.slice(-1));
        if(prizesData){
          setCurrentPrizes(prizesData);
        }

        const entriesData = await scRequests.getRaffleEntries(r.slice(-1));
        if(entriesData){
          setCurrentEntries(entriesData.length);
          let myEntries = 0;
          entriesData.map((entry: any) => {
            if(address && address === entry.toString()){
              myEntries++;
            }
          });
          setMyCurrentEntries(myEntries);
        }

        const winnersData = await scRequests.getRaffleWinners(r.slice(-1));
        if(winnersData){
          setCurrentWinners(winnersData);
          winnersData.map((winner: any) => {
            if(address && address === winner.toString()){
              setIsWinner(true);
            }
          });
        }
      }
    });    
  };

  useEffect(() => {
    getRaffles();
    const interval = window.setInterval(() => {
      getRaffles();
		}, 2000);
		return () => window.clearInterval(interval);
	}, []);

  // rafles history details
  const [rafflesIds, setRafflesIds] = useState([]);
  const [allRaffles, setAllRaffles] = useState([{}]);
  const getHistoryRaffles = async () => {
    const rafflesData = await scRequests.getRaffles();
    const limitedRafflesData = rafflesData ? rafflesData.slice(-11, -1).reverse() : [];

    const rafflesArrays: { id: any, info: {id: 0, end_time: '', start_time: '', slots: 0}, prizes: any, entries: number, userEntries: number, winners: any, userIsWinner: boolean, wallets: number }[] = [];
    if (limitedRafflesData) {
      setRafflesIds(limitedRafflesData);

      const promises = limitedRafflesData.map(async (raffleId : any) => {        
        const raffleData = await scRequests.getRaffleInfo(raffleId);
        const prizesData = await scRequests.getRafflePrizes(raffleId);
        const entriesData = await scRequests.getRaffleEntries(raffleId);
        const winnersData = await scRequests.getRaffleWinners(raffleId);
        
        let userEntries = 0;
        let uniqueEntries = 0;
        if(entriesData){            
          entriesData.map((entry: any) => {
            if(address && address === entry.toString()){
              userEntries++;
            }

            const uniqueBech32Set = new Set(entriesData.map((entry: any) => entry.toString()));
            uniqueEntries = uniqueBech32Set.size;
          });
        }

        let userIsWinner = false;
        if(winnersData){
          winnersData.map((winner: any) => {
            if(address && address === winner.toString()){
              userIsWinner = true;
            }
          });
        }

        const dataToPush = {
          id: raffleId,
          info: raffleData,
          prizes: prizesData,
          entries: entriesData.length,
          userEntries: userEntries,
          winners: winnersData,
          userIsWinner: userIsWinner,
          wallets: uniqueEntries
        };
        rafflesArrays.push(dataToPush);        
      });

      await Promise.all(promises);
    }
    rafflesArrays.sort((a, b) => b.id - a.id);
    setAllRaffles(rafflesArrays);
  };

  useEffect(() => {
    getHistoryRaffles();
    const interval = window.setInterval(() => {
      getHistoryRaffles();
		}, 6000);
		return () => window.clearInterval(interval);
	}, []);

  // get the user tickets balance
  const [ticketsBalance, setTicketsBalance] = useState(0);
  const getTicketsBalance = async (token_identifier : string, token_nonce: number) => {  
    try {
      const response = await fetch(`${config.network.apiAddress}/accounts/${address}/nfts?size=2000`, {
        headers: {
          Accept: 'application/json',
        },
      });
      const json = await response.json();
      if (json) {
        json.map((nft: any) => {
          if (nft.collection === token_identifier && nft.nonce === token_nonce) {            
            setTicketsBalance(nft.balance);
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // get the payment tickets info
  const [ticketsInfo, setTicketsInfo] = useState({token_identifier: '', token_nonce: 0, amount: 0, img_src: ''});
  const getTicketsInfo = async (token_identifier : string, token_nonce: number, amount: number) => {  
    try {
      const response = await fetch(`${config.network.apiAddress}/accounts/${config.raffleContractAddress}/nfts?size=2000`, {
        headers: {
          Accept: 'application/json',
        },
      });
      const json = await response.json();
      if (json) {
        json.map((nft: any) => {
          if (nft.collection === token_identifier && nft.nonce === token_nonce) {
            setTicketsInfo({token_identifier: token_identifier, token_nonce: token_nonce, amount: amount, img_src: nft?.url});
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // tickets buy amount
  const [buyAmount, setBuyAmount] = useState<number>(1);
  const handleIncrement = () => {
    setBuyAmount(buyAmount + 1);
  };
  const handleDecrement = () => {
    if(buyAmount <= 1) return;
    setBuyAmount(buyAmount - 1);
  };

  // get prize images from db
  const [prizeImages, setPrizeImages] = useState<Prize[]>([]);
  const fetchPrizes = async () => {
    const prizesData = await getAllRafflePrizes();
    setPrizeImages(prizesData);
  };
  useEffect(() => {
    fetchPrizes();
  }, []);
  
  return (
    <Layout>
      <div className='container'>
        <div className={`${styles.headerBox2}`}>
          {currentRaffle.end_time && <Timer startTimestamp={currentRaffle.end_time * 1000}/>}
        </div>

        {/* <div className={`${styles.headerBox2}`} style={{marginTop: '100px'}}>
          <p className={`${styles.howToTitle} text-center`}>HOW TO PLAY</p>
          <Row>
            <Col xs={12} lg={4} className='mt-3'>
              <div className={`${styles.playCard} ${styles.playCardOne} ${styles.bgImg}`}>
                <div className={styles.playCardIcon}>
                  <ScreenSearchDesktopIcon style={{ fontSize: 60, color: 'inherit' }} />
                  <span className={styles.playCardNumber}>01</span>
                </div>
                <div className={styles.playCardContent}>
                  <h3 className={styles.playCardTitle}>Check</h3>
                  <p>Make sure you have the required SFTS to buy tickets</p>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mt-3'>
              <div className={`${styles.playCard} ${styles.playCardTwo} ${styles.bgImg}`}>
                <div className={styles.playCardIcon}>
                  <ShoppingCartIcon style={{ fontSize: 60, color: 'inherit' }} />
                  <span className={styles.playCardNumber}>02</span>
                </div>
                <div className={styles.playCardContent}>
                  <h3 className={styles.playCardTitle}>Buy</h3>
                  <p>Select the desired tickets number and press the buy button</p>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mt-3'>
              <div className={`${styles.playCard} ${styles.playCardThree} ${styles.bgImg}`}>
                <div className={styles.playCardIcon}>
                  <EmojiEventsIcon style={{ fontSize: 60, color: 'inherit' }} />
                  <span className={styles.playCardNumber}>03</span>
                </div>
                <div className={styles.playCardContent}>
                  <h3 className={styles.playCardTitle}>WIN</h3>
                  <p>The prize will be sent to you after the raffle round has ended</p>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}

        <div className={styles.headerBox2} style={{marginTop: '100px'}}>
          <p className={`${styles.howToTitle} text-center`}>CURRENT RAFFLE DETAILS</p>
          <Row>
            <Col xs={12} lg={8} className='mt-3'>
              <div className={`${styles.raffleInfoBox}`}>
                <p className='text-warning text-center'>Enter for a chance to win</p>
                <p className={`${styles.prizesTitle} text-center`}>EXCLUSIVE SFTS, NFTS AND TOKENS</p>
                <div className='d-flex justify-content-between text-capitalize p-2 mt-4'>
                  <p className='mt-1'><span style={{whiteSpace: 'nowrap'}}>raffle no.</span> <span className='text-warning small'>{lastRaffleId.toString()}</span></p>
                  <p className='mt-1 text-right'>draw date: <span className='text-warning small'>{currentRaffle.end_time ? formatDate(currentRaffle.end_time * 1000) : ''}</span></p>
                </div>
                <div className='mx-2' style={{borderBottom: '1px solid gray'}}/>

                <div className='p-2 mt-5'>
                  <p className='h2 text-center'>RAFFLE PRIZES</p>
                  {prizeImages.length > 0 && (
                    <Carousel
                      responsive={responsive}
                      autoPlay={false}
                      showDots={false}
                      keyBoardControl={true}
                    >
                      {prizeImages.map((prize: any, index) => (
                        <div key={index} style={{maxWidth: '100%'}}>
                          <div className='p-2'>
                            <p className='h5 mt-1 text-center '>Prize {index+1}</p>
                            <img src={prize?.imageUrl} className={`${styles.prizeImage}`} />
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  )}
                </div>

                <div className='p-2 mt-5'>
                  <p className='h2 text-center'>RAFFLE WINNERS</p>
                  {isWinner && <p className='h5' style={{color: '#32CD32'}}>Congratulations, you are one of the winners!</p>}
                  {currentWinners.length > 0 ? (
                    <div className='mt-3'>
                      {currentWinners.map((winner: any, index) => (
                        <p key={index} className='small' style={{marginTop: '-5px'}}>{index+1}. {winner.toString().slice(0, 8)} ... {winner.toString().slice(56, 62)}</p>
                      ))}
                    </div>
                  ) : (
                    <p className='small p-3 text-justify b-r-sm' style={{backgroundColor: '#191919'}}>Winners will be displayed after draw</p>
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mt-3'>
              <div className={`${styles.raffleBuyBox} ${styles.raffleBuyBoxImage}`}>                
                {currentRaffle.end_time && <MiniTimer startTimestamp={currentRaffle.end_time * 1000}/>}

                <div className='p-2 mt-2'>
                  <h5 className='text-center' style={{whiteSpace: 'nowrap'}}>Tickets</h5>
                  <div className='d-flex justify-content-between'>                    
                    <span className='small' style={{whiteSpace: 'nowrap'}}>Yours</span>
                    <span className='text-right small' style={{whiteSpace: 'nowrap'}}>Total Entries</span>
                  </div>                  
                  <ProgressBar totalCount={currentEntries} leftCount={currentEntries - myCurrentEntries} activeColor="#32CD32"/>
                </div>

                <div className='p-2 mt-2'>
                  <div className='d-flex justify-content-between'>
                    <h5 className=''>Price</h5>
                    <p>{ticketsInfo.amount.toString()} SFT{ticketsInfo.amount == 1 ? '' : 'S'} {ticketsInfo.token_identifier}</p>
                  </div>                  
                  <div className='text-center'>
                    <img src={ticketsInfo.img_src} className={styles.paymentInfo}/>
                  </div>
                </div>

                <div className='p-2 mt-2'>
                  <div className='d-flex justify-content-between'>
                    <h5 className=''>Balance</h5>
                    <p>{ticketsBalance.toString()} SFT(s)</p>
                  </div>                  
                </div>

                <div className='px-2 d-flex justify-content-center'>
                  <div className={styles.buyContainer}>
                    <button className={styles.buyButton} onClick={handleDecrement}>-</button>
                    <span className={styles.buyValueDisplay}>{buyAmount}</span>
                    <button className={styles.buyButton} onClick={handleIncrement}>+</button>
                  </div>
                </div>

                <div className='mt-4'>
                  <Button
                    size='large'
                    onClick={useRaffleBuyTickets(lastRaffleId, payment.token_identifier, payment.token_nonce, payment.amount, buyAmount)}
                    className={address || hasPendingTransactions || !isEnded ? (`${styles.buttonLight} text-white font-bold`) : (`${styles.buttonLight} ${styles.disabled} font-bold`)}
                  >
                    {isEnded ? 'Raffle Ended' : 'Buy Tickets'}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className={styles.headerBox2} style={{marginTop: '100px'}}>
          <p className={`${styles.howToTitle} p-2 text-center`} style={{marginBottom: '-15px'}}>RAFFLES HISTORY</p>
            {allRaffles.length > 0 ? (
            <Carousel
              infinite
              responsive={responsive}
              autoPlay={false}
              showDots={false}
              keyBoardControl={true}
              autoPlaySpeed={3000}
            >
              {allRaffles.map((raffle: any, index) => (
                <div key={index}>
                  <div className={`${styles.historyCard} mx-3 mt-5`}>
                    <p className='text-center text-capitalize'>Raffle no. {raffle.id && raffle.id.toString()}</p>
                    <div className='mt-1 d-flex justify-content-between'>
                      <div>
                        <CalendarTodayIcon fontSize='small' className='me-2' style={{marginTop: '-3px'}}/>
                        <span className='small' style={{color: '#32CD32'}}>{raffle?.info?.end_time ? formatOnlyDate(raffle.info.end_time * 1000) : ''}</span>
                      </div>
                      <div>
                        <ScheduleIcon fontSize='small' className='me-2' style={{marginTop: '-3px'}}/>
                        <span className='small' style={{color: '#32CD32'}}>{raffle?.info?.end_time ? formatOnlyHour(raffle.info.end_time * 1000) : ''}</span>
                      </div>
                    </div>
                    <div className='p-1' style={{borderBottom: '1px solid gray'}}/>

                    <div className='mt-2 d-flex justify-content-between'>
                      <span className='small'>Prizes Number</span>
                      <span className='small' style={{color: '#32CD32'}}>{raffle?.prizes ? raffle.prizes.length : '0'}</span>
                    </div>
                    <div className='mt-1 d-flex justify-content-between'>
                      <span className='small'>Sold Tickets</span>
                      <span className='small' style={{color: '#32CD32'}}>{raffle?.entries ? raffle.entries.toString() : '0'}</span>
                    </div>                      
                    <div className='mt-1 d-flex justify-content-between'>
                      <span className='small'>Participants</span>
                      <span className='small' style={{color: '#32CD32'}}>{raffle?.wallets ? raffle.wallets.toString() : '0'}</span>
                    </div>
                    <div className='p-1' style={{borderBottom: '1px solid gray'}}/>

                    {address && (
                      <>                          
                        <div className='mt-2 d-flex justify-content-between'>
                          <span className='small'>Your Tickets</span>
                          <span className='small' style={{color: '#32CD32'}}>{raffle?.userEntries ? raffle.userEntries.toString() : '0'}</span>
                        </div>
                        <div className='mt-1 d-flex justify-content-between'>
                          <span className='small'>Prize Winner</span>
                          <span className='small' style={{color: '#32CD32', marginRight: '-5px'}}>{raffle.userIsWinner ? <CheckIcon fontSize='small' color='success'/> : <CloseIcon fontSize='small' color='error'/>}</span>
                        </div>
                        <div className='p-1' style={{borderBottom: '1px solid gray'}}/>
                      </>                        
                    )}

                    <div>
                      {raffle?.prizes?.length > 0 && (
                        <Carousel
                          responsive={responsive2}
                          autoPlay={false}
                          showDots={false}
                          keyBoardControl={true}
                          autoPlaySpeed={3000}
                        >
                          {raffle.prizes.map((prize: any, index2: any) => (
                            <div key={index2} className='mt-3 p-2' style={{ border: '1px dotted #32CD32' }}>
                              <div className='text-center'>
                                <p className='text-center'>Prize {index2 + 1}</p>
                                <div style={{marginTop: '-10px'}}>
                                  {prize?.token_nonce ? (
                                    <span>{prize.token_nonce == 0 ? new BigNumber(prize.amount).shiftedBy(-18).toFixed() : prize.amount.toString()}</span>
                                  ) : ('')}                                    
                                  {prize?.token_nonce > 0 ? (
                                    <span className='ms-2'>{prize.token_identifier}</span>
                                  ):(
                                    <span className='ms-2'>{prize.token_identifier.match(/^[^-]+/)?.[0] || ''}</span>
                                  )}
                                  {prize?.token_nonce > 0 ? (
                                    <>
                                      <span>{prize.token_nonce > 9 ? '-' : '-0'}</span>
                                      <span>{prize.token_nonce.toString()}</span>
                                    </>
                                  ):('')}
                                </div>
                              </div>
                            </div>
                          ))}
                        </Carousel>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          ) : ('')}
        </div>
      </div>

      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </Layout>
  );
};
