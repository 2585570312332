import { LoadingScreen } from 'components/LoadingScreen';
import { Web3LotteryContext } from 'contexts/Web3LotteryContext';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import Tilt from 'react-parallax-tilt';
import Ripples from 'react-ripples';
import { Layout } from 'components/Layout/Layout';
import { TicketCard } from './components/TicketCard';
import { PickTicketDialog } from './components/TicketCard/PickTicketDialog';
import { FixedLengthArray } from 'typesS';
import { Ticket } from 'Ticket';
import { useBuyTicket } from 'contexts/hooks/transactions/useBuyTicket';
import { useBuyBulkTickets } from 'contexts/hooks/transactions/useBuyBulkTickets';
import Timer from '../Lottery/components/Timer/Timer';
import * as scRequests from '../../contexts/scLotteryRequests';
import { LastWinningNumbers, getCorrectIndex } from './components/WinningNumbers/LastWinningNumbers';
import { HistoryCarousel } from './components/HistoryCarousel';
import TicketModal from './components/TicketModal/TicketModal';
import PastWinningTickets from './components/PastWinningTickets/PastWinningTickets';
import styles from './lottery.module.scss';
import { FormatAmount } from '@multiversx/sdk-dapp/UI/FormatAmount/FormatAmount';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import {Address, BigUIntValue} from '@multiversx/sdk-core/out';
import axios from 'axios';
import * as config from '../../config';
import { useGetTokenBalance } from 'contexts/api/useGetTokenBalance';
import { useGetTokenData } from 'contexts/api/useGetTokenData';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, DialogContentText, IconButton, Input, Box, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import { TicketCardNumber } from './components/TicketCard/TicketCardNumber';
import {getUserTickets} from '../../contexts/scLotteryRequests';
import toast, {Toaster} from 'react-hot-toast';
import { UserContext } from 'contexts';

const componentsProps = {
	tooltip: {
		sx: {
			maxWidth: '200px',
			backgroundColor: 'black',
			color: 'white',
			fontSize: '14px',
			fontWeight: '400',
			textAlign: 'center',
			borderRadius: '10px',
			padding: '10px',
			top: '-10px'
		},
	},
	arrow: {
		sx: {
			color: 'black',
		},
	},
	TransitionComponent: Fade,
};

interface BulkTicket {
  user_stake: BigNumber,
  first: number;
  second: number;
  third: number;
}

export const LotteryPage = () => {
  const { platformUser, updateReferralPoints } = useContext(UserContext);
  const account = useGetAccount();
  const { address } = useGetAccount();
  const context = useContext(Web3LotteryContext);
  const [ticket, setTicket] = useState<FixedLengthArray<[number, number, number]>>([1, 2, 3]);
  const [bet, setBet] = useState(0);
  const [duration, setDuration] = useState(0);
  const [userBalance, setUserBalance] = useState('');
  const storedPoints = localStorage.getItem('storedPoints') ?? '0';
  const storedTimestamp = parseInt(localStorage.getItem('localTimestamp') ?? '0', 10);
  const { success } = useGetActiveTransactionsStatus();

  const currentLotteryId = context.currentLotteryId;
  const getTokenBalance = useGetTokenBalance();

  const [tokenTicker, setTokenTicker] = useState('');
  const [tokenLogo, setTokenLogo] = useState('');
  const getTokenData = useGetTokenData();

  // build tickets for sc buy function
  const [bulkTickets, setBulkTickets] = useState<BulkTicket[]>([]);

  const handleTicketChange = (ticketPar: Ticket) => {
    setTicket(ticketPar.numbers);
  };

  //
  const [userTickets, setUserTickets] = useState([[0,0,0]]);
  const refreshUserTickets = async () => {
    const userTicketsSC = await scRequests.getUserTickets(new Address(address), context.currentLotteryId);
    setUserTickets(userTicketsSC);
  };

  useEffect(() => {
    if(address){
      refreshUserTickets();
    }
    const interval = window.setInterval(() => {
      if(address){
        refreshUserTickets();
      }
    }, 3000);
    return () => window.clearInterval(interval);
  }, [context]);

  const handleBet = (betVar: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = betVar.target.value;
    const numericValue = parseFloat(inputValue);

    if (!isNaN(numericValue)) {
      setBet(numericValue);

      // Update user_stake for each element in bulkTickets
      const updatedBulkTickets = bulkTickets.map((ticket) => ({
        ...ticket,
        user_stake: new BigNumber(numericValue).multipliedBy(new BigNumber(10).pow(18)),
      }));

      setBulkTickets(updatedBulkTickets);
    } else {
      setBet(0);
    }
  };

  const handleMaxBet = () => {
    const numericValue = context.maxTokenAmount? new BigNumber(context?.maxTokenAmount).dividedBy(new BigNumber(10).pow(18)).toNumber() : 0;

    if (!isNaN(numericValue)) {
      setBet(numericValue);

      // Update user_stake for each element in bulkTickets
      const updatedBulkTickets = bulkTickets.map((ticket) => ({
        ...ticket,
        user_stake: new BigNumber(numericValue).multipliedBy(new BigNumber(10).pow(18)),
      }));

      setBulkTickets(updatedBulkTickets);
    } else {
      setBet(0);
    }
  };


  const buyTicket = useBuyTicket(ticket, bet);

  useEffect(() => {
    // console.log('context start time lottery page', context.startTime);
    const startTime = context.startTime;
    const now = new Date().getTime() / 1000;

    if (now - startTime > 1800) {
      setDuration(0);
    }
    else {
      const diff = now - startTime;
      setDuration(1800 - diff);
    }

  }, [context.currentLotteryId, context.startTime]);



  useEffect(() => {
    getTokenBalance(account.address).then((data) => {
      if (data?.data?.balance !== undefined && data?.data?.balance !== null) {
        setUserBalance(data?.data?.balance.toString());
      }
    });
  }, [account.address, context.rewardTokenId]);

  useEffect(() => {
    getTokenData().then((data) => {
      if(data?.assets?.pngUrl){
        setTokenLogo(data.assets.pngUrl);
      }
      if(data?.ticker){
        setTokenTicker(data.name);
      }

    });
  }, [context.rewardTokenId]);

  //multiple bet dialog
  const [openDialogMB, setOpenDialogMB] = useState(false);
  const handleOpenMB = () => {
    setOpenDialogMB(true);
  };
  const handleCloseMB = () => {
    setOpenDialogMB(false);
    setBulkTicketsNumber(0);
    setBulkTickets([]);
  };

  // tickets number
  const [bulkTicketsNumber, setBulkTicketsNumber] = useState(0);
  const handleBulkTicketsInput = (event: any) => {
		if (event.target.value === '' || isNaN(Number(event.target.value)) || event.target.value === 0){
			setBulkTicketsNumber(0);
      setBulkTickets([]);
		}else if(event.target.value > (50 - context.userTickets.length)){
      setBulkTicketsNumber(50 - context.userTickets.length);
      generateBulkTickets(50 - context.userTickets.length);
    }else{
    	const newTicketsNumber = Number(event.target.value);
      setBulkTicketsNumber(newTicketsNumber);
      generateBulkTickets(newTicketsNumber);
		}
  };

  const handleInputChange = (index: number, field: keyof BulkTicket) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newTickets = [...bulkTickets];

    if (field === 'user_stake') {
      // Assuming BigUIntValue has a parsing or conversion method, replace the following line accordingly
      newTickets[index][field] = new BigNumber(event.target.value) || new BigNumber(0);
    } else {
      newTickets[index][field] = Number(event.target.value) || 0;
    }

    setBulkTickets(newTickets);
  };

  const generateBulkTickets = (num: number) => {
    const newTickets = Array.from({ length: num }, () => ({ user_stake: new BigNumber(bet).multipliedBy(new BigNumber(10).pow(18)), first: 0, second: 0, third: 0 }));
    setBulkTickets(newTickets);
  };

  //#region shuffle and generate values for tickets
  const shuffleValues = () => {
    const minRandomValue = 1;
    const maxRandomValue = context?.maxNumber;

    // Use a set to keep track of generated combinations
    const uniqueCombinations = new Set();

    // Assign shuffled values to each ticket
    const newTickets = bulkTickets.map(() => {
      const user_stake = new BigNumber(bet).multipliedBy(new BigNumber(10).pow(18));
      let ticketValues: number[];

      let combinationKey;

      do {
        // Generate a unique combination key
        ticketValues = [];
        while (ticketValues.length < 3) {
          const newValue = Math.floor(Math.random() * (maxRandomValue - minRandomValue + 1)) + minRandomValue;
          if (!ticketValues.includes(newValue)) {
            ticketValues.push(newValue);
          }
        }
        combinationKey = ticketValues.slice().sort().join('-');
      } while (uniqueCombinations.has(combinationKey) || isCombinationInUserTickets(ticketValues));

      // Add the combination key to the set
      uniqueCombinations.add(combinationKey);

      return {
        user_stake,
        first: ticketValues[0],
        second: ticketValues[1],
        third: ticketValues[2],
      };
    });

    // Update state with new tickets
    setBulkTickets(newTickets);
  };

// Function to check if the combination is in userTickets
  const isCombinationInUserTickets = (combination: number[]) => {
    return userTickets.some((userTicket) => {
      return (
        userTicket[0] === combination[0] &&
        userTicket[1] === combination[1] &&
        userTicket[2] === combination[2]
      );
    });
  };
  //#endregion shuffle;

  const disabledButton = bulkTickets.length === 0 || bulkTickets.some(ticket => ticket.first === 0 || ticket.second === 0 || ticket.third === 0) || bet === 0;
  const myTicket =  new Ticket(ticket);

  // image checker
  const [imageError, setImageError] = useState(false);
  const randomQueryParam = Math.random();

  const handleImageError = () => {
    setImageError(true);
  };

  // points transaction tracker - single buy
  const pointsTransactionAPI = config.network.apiAddress + '/transactions?size=1&sender=' + address + '&receiver=' + config.lotteryContractAddress + '&function=buyTicket&after=' + storedTimestamp;
  useEffect(() => {
		if(success && parseFloat(storedPoints) > 0) {
			const getTransactionData = async () => {
				try {
					const response = await fetch(pointsTransactionAPI);
					return await response.json();
				} catch (error) {
					console.error(error);
				}
			};
			getTransactionData().then(r => {        
				if(r && Object.keys(r).length) {         
          if(platformUser) {
            const points = parseFloat(storedPoints);
            updateReferralPoints(platformUser?.uid, points);
            toast.success(`Congratulations, you received ${points} points for your transaction`, {duration: 5000});
            localStorage.setItem('storedPoints', '0');
          }
				}				
			});
		}
	}, [success]);

  // points transaction tracker - multiple buy
  const mPointsTransactionAPI = config.network.apiAddress + '/transactions?size=1&sender=' + address + '&receiver=' + config.lotteryContractAddress + '&function=buyBulkTicket&after=' + storedTimestamp;
  useEffect(() => {
		if(success && parseFloat(storedPoints) > 0) {
			const getTransactionData = async () => {
				try {
					const response = await fetch(mPointsTransactionAPI);
					return await response.json();
				} catch (error) {
					console.error(error);
				}
			};
			getTransactionData().then(r => {        
				if(r && Object.keys(r).length) {         
          if(platformUser) {
            const points = parseFloat(storedPoints);
            updateReferralPoints(platformUser?.uid, points);
            toast.success(`Congratulations, you received ${points} points for your transaction`, {duration: 5000});
            localStorage.setItem('storedPoints', '0');
          }
				}				
			});
		}
	}, [success]);

  return (
    <Layout>
      <div className='container'>
        <div className={styles.headerBox}>
          <div className='mt-3'></div>
          <div className={styles.headerText}>
            <div className=''>
              HOME
            </div>
            <div className={styles.crumbElement}> &gt; LOTTERY</div>
          </div>
          <div className='mb-2'></div>
          <h3>A fun and exciting adventure awaits</h3>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <div className='container h-100'>
              <div className='row h-100'>
                <div className={styles.bigContainer}>                  
                  <div className={styles.timerContainer}>
                    <div className={styles.timerBox}>

                      <div className='d-flex flex-row align-items-center gap-2 mb-2'>
                        <h4 className={` ${styles.boxTitle}`}>
                          Lottery type
                        </h4>
                        <div className={`${styles.historyId}`} style={{marginTop: '-5px'}}>
                          {context?.numbersToExtract?.valueOf()}/{context?.maxNumber?.valueOf()}
                        </div>
                      </div>
                      <div className='d-flex flex-row justify-content-evenly gap-3'>
                        <div className='d-flex justify-content-center text-center flex-column align-items-center'>
                          <div className={styles.dateRight2}>
                            <div className={styles.dateTitleRight}>{context.multiplier?.valueOf()}x</div>
                            <div className={styles.dateTextRight}>POSSIBLE WIN</div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-center text-center flex-column align-items-center'>
                          <div className={styles.dateRight2}>
                            <div className={styles.dateTitleRight}>
                              {new BigNumber(context.maxTokenAmount ? context.maxTokenAmount : 0).dividedBy(new BigNumber(10).pow(18)).toNumber()} {tokenTicker}
                              <img src={tokenLogo} style={{width: 'auto', height: '40px', marginLeft: '5px', marginTop: '-5px'}} />
                            </div>
                            <div className={styles.dateTextRight}>MAX TOKEN AMOUNT PER TICKET</div>
                          </div>
                        </div>
                      </div>    
                      {!imageError && (
                        <>
                          <h4 className={`mb-3 mt-5 ${styles.boxTitle}`}>
                            Lottery #{context.currentLotteryId}
                          </h4>
                          <img
                            src={`https://x-launcher.com/files/xBid/lottery_home.svg?${randomQueryParam}`}
                            style={{ maxWidth: '100%', width: '100%', borderRadius: '10px' }}
                            onError={handleImageError}
                          />
                        </>
                      )}
                      <h4 className={`mt-5 ${styles.boxTitle}`}>
                        Numbers picked in
                      </h4>
                      <div className={`${styles.timerBox}`}>  
                        <Timer duration={duration} />
                      </div>

                    </div>
                  </div>                  
                </div>

              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className={styles.timerContainerRight}>
                    <div className={styles.timerBoxRight}> 
                      <h4 className={`${styles.boxTitle}`}>
                        Ticket
                      </h4>                     
                      <div className='row'>
                        <div className='col-12'>
                          <div className=''>                            
                            <div className='d-flex flex-column gap-3'>
                              <div className="d-flex w-100 justify-content-between flex-row mt-2">
                                {myTicket.numbers.map((number, index) => (
                                  <div key={index} className={styles.numbersRight}>
                                    <div className={`text-white font-bold ${getCorrectIndex(index)}`}>
                                        <div className={styles.dateText}>
                                          <TicketCardNumber number={number} />
                                        </div>
                                      </div>
                                  </div>
                                ))}
                              </div>
                              <div className='d-flex flex-row gap-2'>
                                <div className="input-group">
                                  <span className={`input-group-text ${styles.bet}`} style={{padding: '5px'}}>Bet</span>
                                  <input
                                    type="text"
                                    style={{
                                      minWidth: '60px', 
                                      fontSize: '16px',
                                      WebkitAppearance: 'none',
                                      MozAppearance: 'textfield',
                                      appearance: 'textfield',
                                      textAlign: 'center'
                                    }}
                                    className={address ? (`form-control ${styles.numberInput}`) : (`form-control ${styles.numberInput} ${styles.disabled}`)}
                                    id="inputText2"
                                    onChange={e => handleBet(e)}
                                    value={bet}
                                    max={new BigNumber(context?.maxTokenAmount ? context?.maxTokenAmount : 0).dividedBy(new BigNumber(10).pow(18)).toNumber()}
                                    onWheel={(e) => e.preventDefault()}
                                    onKeyDown={(e) => {
                                      if (
                                        !/[0-9]/.test(e.key) &&
                                        e.key !== 'Backspace' &&
                                        e.key !== 'Delete' &&
                                        !['ArrowLeft', 'ArrowRight'].includes(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}                                    
                                  />
                                </div>
                                <a
                                  className={address ? (`${styles.buttonDark} ${styles.maxButton} p-1`) : (`${styles.buttonDark} ${styles.disabled} p-1`)}
                                  onClick={() => setBet(context.maxTokenAmount? new BigNumber(context?.maxTokenAmount).dividedBy(new BigNumber(10).pow(18)).toNumber() : 0)}
                                  title='Max'
                                  style={{width: 'auto'}}
                                >
                                  Max
                                </a>
                                <TicketCard ticket={new Ticket(ticket)} onTicketChange={handleTicketChange} />                                
                              </div>
                              <div className='d-flex justify-content-between gap-3'>
                                <a
                                  className={address ? (`${styles.buttonDark}`) : (`${styles.buttonDark} ${styles.disabled}`)}
                                  onClick={buyTicket}
                                  title='Buy ticket'
                                  style={{padding: '0 !important'}}
                                >
                                  Buy Ticket
                                </a>
                                <a
                                  className={address ? (`${styles.buttonDark}`) : (`${styles.buttonDark} ${styles.disabled}`)}
                                  onClick={handleOpenMB}
                                  title='Multiple ticket2'
                                  style={{padding: '0 !important'}}
                                >
                                  Multiple Tickets
                                </a>
                              </div>
                              <div className='d-flex flex-row justify-content-between align-items-center gap-3'>
                                <TicketModal />
                                <PastWinningTickets />
                              </div>
                              {
                                (address && userBalance !== undefined && !Number.isNaN(userBalance)) && (
                                  <div className='d-flex flex-row justify-content-start align-items-center gap-3'>
                                    <div className={styles.historyTitleMod}>Balance </div>

                                    <div className={styles.historyId} >
                                      {(userBalance === '0' || userBalance === '') ? (
                                        <div className=''>
                                          0{' '}
                                          {tokenTicker}
                                        </div>
                                      ) : (
                                        <div className=''>
                                          {tokenTicker === 'USDC' && (
                                            new BigNumber(userBalance ? userBalance : 0)
                                              .dividedBy(new BigNumber(10).pow(new BigNumber(6)))
                                              .toNumber().toFixed(4)
                                          )}
                                          {tokenTicker !== 'USDC' && (
                                            new BigNumber(userBalance ? userBalance : 0)
                                              .dividedBy(new BigNumber(10).pow(new BigNumber(18)))
                                              .toNumber().toFixed(4)
                                          )}
                                          {' '}
                                          {tokenTicker}
                                        </div>
                                      )}

                                    </div>
                                  </div>
                                )}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                    (currentLotteryId - 2 >= 0
                      && context.lotteryHistory.length !== 0
                      && context.lotteryHistory !== undefined
                      && context.lotteryHistory[0] !== undefined
                    )
                    && (
                      <>
                        <div className=''>
                          <div className={styles.timerBox}>
                            <div className={styles.boxTitle} >
                              <h4 className='mb-3'>
                                Last winning numbers
                              </h4>
                            </div>

                            {/* change this index into (x-1) when changing idFrom in context getLotteryHistoryByIdFrom(currentLotteryId - x) */}
                            {context.lotteryHistory.length !== 0 && (
                              <LastWinningNumbers numbers={convertToNumbers(context.lotteryHistory[9]?.field2)} />
                            )}

                          </div>
                        </div>
                      </>
                    )
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {context.lotteryHistory !== undefined && context.lotteryHistory.length === 0 ? (
          <>
            {/* <p className={styles.historyTime}>
              No past lotteries
            </p> */}
          </>
        ) : (
          <>
            <div className={styles.headerBox}>
              <div className='mt-3'></div>
              <div className={styles.headerText}>
                <div className=''>
                  HOME
                </div>
                <div className={styles.crumbElement}> &gt; LOTTERY HISTORY</div>
              </div>
              <div className='mb-2'></div>
              <h3>Lottery history</h3>            
            </div>

            <div className={styles.historyBox}>              
              <div className='mb-5'>
                <HistoryCarousel />
              </div>                
            </div>
          </>
        )}
      </div>

      <Dialog open={openDialogMB} onClose={handleCloseMB} fullWidth 
        sx={{
          backgroundColor: 'transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#180437',
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(6, 11, 40, 0.74)',
          },
        }}
      > 
        <div className={styles.numbersModal}>       
          <DialogTitle sx={{ m: 0, p: 2, fontSize: '25px' }} id="customized-dialog-title" className='text-center'>
            Multiple Tickets
          </DialogTitle>
          <IconButton
            size='small'
            aria-label='close'
            onClick={handleCloseMB}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'red'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent >
            <Row>
              <Col xs={12} lg={6} style={{borderRight: '1px solid white'}}>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <p className="text-white mb-1">
                    Tickets number:
                  </p>
                  <Input
                    value={bulkTicketsNumber}
                    size="small"
                    placeholder=""
                    onChange={handleBulkTicketsInput}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    disableUnderline
                    disabled={false}
                    className="text-white font-bold px-2 pt-1 b-r-xs"
                    style={{border: '0.5px solid rgb(74, 85, 104)', width: '25%', textAlign: 'center', fontSize: '16px'}}
                  />
                  <Tooltip key='showInfo' title={`Max 50 tickets per round. Bought tickets: ${context.userTickets.length}, Left: ${50 - context.userTickets.length} `} arrow placement='bottom' componentsProps={componentsProps}>
                    <Button 
                      className={`${styles.buttonDark} text-white`} 
                      onClick={() => {setBulkTicketsNumber(50 - context.userTickets.length); generateBulkTickets(50 - context.userTickets.length);}}
                      style={{width: 'auto'}}
                    >
                      Max
                    </Button>
                  </Tooltip>
                </div>
                <Row>
                  <Col xs={12}>
                    <Button className={`${styles.buttonDark} text-white`} onClick={shuffleValues}>Auto Generate</Button>
                  </Col>
                </Row>
                <div className='mt-3 mb-5'>                  
                  {bulkTickets && bulkTickets.map((ticket, index) => (
                    <div key={index} className='mt-2'>
                      <p className='mb-1'>Ticket #{index+1}</p>
                      <div className='d-flex justify-content-between'>
                        <div className='back1' style={{height: '38px'}}>                          
                          <Input
                            value={ticket.first}
                            size="medium"
                            placeholder=""
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleInputChange(index, 'first')}
                            disableUnderline
                            className="text-white font-bold font-size-lg px-2 pt-1 b-r-xs"
                            style={{border: '0.5px solid rgb(74, 85, 104)', width: '100%', textAlign: 'center', color: 'red'}}
                          />
                        </div>
                        <div className='back2' style={{height: '38px'}}>
                          <Input
                            value={ticket.second}
                            size="medium"
                            placeholder=""
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleInputChange(index, 'second')}
                            disableUnderline
                            className="text-white font-bold font-size-lg px-2 pt-1 b-r-xs"
                            style={{border: '0.5px solid rgb(74, 85, 104)', width: '100%', textAlign: 'center'}}
                          />
                        </div>
                        <div className='back3' style={{height: '38px'}}>
                          <Input
                            value={ticket.third}
                            size="medium"
                            placeholder=""
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleInputChange(index, 'third')}
                            disableUnderline
                            className="text-white font-bold font-size-lg px-2 pt-1 b-r-xs"
                            style={{border: '0.5px solid rgb(74, 85, 104)', width: '100%', textAlign: 'center'}}
                          />
                        </div>
                      </div>
                      <div className='mx-3 mt-3' style={{borderBottom: '1px dashed gray'}}/>
                    </div>
                  ))}
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <p className="text-white mb-1">
                    Bet per ticket:
                  </p>                  
                  <input
                    type="text"
                    style={{
                      height: '33px',
                      fontSize: '17px',
                      WebkitAppearance: 'none',
                      MozAppearance: 'textfield',
                      appearance: 'textfield',
                      border: '0.5px solid rgb(74, 85, 104)', width: '50%', textAlign: 'center',
                      marginTop: '-2px',
                    }}
                    className={(`form-control ${styles.numberInput} font-bold text-green-A200`)}
                    id="inputTextB"
                    onChange={e => handleBet(e)}
                    value={bet}
                    max={new BigNumber(context?.maxTokenAmount ? context?.maxTokenAmount : 0).dividedBy(new BigNumber(10).pow(18)).toNumber()}
                    onWheel={(e) => e.preventDefault()}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Delete' &&
                        !['ArrowLeft', 'ArrowRight'].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}                                    
                  />
                </div>
                <div>
                  <Row>
                    <Col xs={12}>
                      <Button 
                        className={`${styles.buttonDark} text-white`} 
                        onClick={() => handleMaxBet()}
                        style={{marginTop: '6px'}}
                      >
                        Max Bet
                      </Button>
                      <div className='mt-5' style={{borderBottom: '1px dashed gray'}}/>
                      <div className='d-flex justify-content-between'>
                        <p className="text-white mt-2">
                          Total bet:
                        </p> 
                        <p className="text-white mt-2">
                          {bet * bulkTicketsNumber} {''}
                          {tokenTicker}  {''}
                          <img src={tokenLogo} style={{width: 'auto', height: '40px', marginLeft: '5px', marginTop: '-5px'}} />
                        </p> 
                      </div>
                      <Button
                        className={disabledButton ? (`${styles.buttonDark} ${styles.disabled}`) : (`${styles.buttonDark} text-white`)}
                        onClick={useBuyBulkTickets(bulkTickets, bet * bulkTicketsNumber, 10000000 * (bulkTicketsNumber + context.userTickets.length), () =>handleCloseMB)}
                        disabled={disabledButton}
                      >
                        Send Tickets
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>            
          </DialogContent>
        </div>
      </Dialog>

      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </Layout>
  );

};

export function convertToNumbers(bigNumbers: BigNumber[]): number[] {
  return bigNumbers?.map((bigNum) => bigNum.toNumber());
}