import {
  AbiRegistry,
  Address,
  AddressValue,
  BigUIntValue,
  ResultsParser,
  SmartContract,
  TokenIdentifierValue,
  U64Value
} from '@multiversx/sdk-core';

import { network, stakingStonesContractAddress as contractAddress } from 'config';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import contractAbi from './abi/staking-stones.abi.json';

export const Provider = new ProxyNetworkProvider(network.gatewayAddress, {
  timeout: 20000 //modify this for testing, change api entirely with blast api
});
export const resultsParser = new ResultsParser();

export const getSmartContractObj = async () => {
  const abiRegistry = await AbiRegistry.create(formatAbiJson(contractAbi));
  return new SmartContract({
    address: new Address(contractAddress),
    abi: abiRegistry
  });
};

const formatAbiJson = (abi: any) => {
  return {
    name: abi.name,
    endpoints: abi.endpoints,
    types: abi.types
  };
};

export const getUserStakedData = async (address: Address) => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getSftsStaked([new AddressValue(address)]);

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getUserRewardsData = async (address: Address, nonce: number, amount: number) => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.calculateRewards([new U64Value(nonce), new AddressValue(address), new BigUIntValue(amount)]);

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getUserTotalRewards = async (address: Address) => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getUserReward([new AddressValue(address)]);

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};