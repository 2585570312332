import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network, xBidToken } from 'config';
import { getSmartContractObj } from 'contexts/scMysteryPrizesRequests';
import { FixedLengthArray } from 'typesS';
import { Address, Transaction, TransactionWatcher, U64Value, TokenTransfer, BigUIntValue } from '@multiversx/sdk-core/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './useSendAndSign';
import BigNumber from 'bignumber.js';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';

const watcher = new TransactionWatcher(new ApiNetworkProvider(network.apiAddress), { patienceMilliseconds: 8000 });

const sendAndSignTransactionsWrapped = async (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
): Promise<{
    success: boolean;
    error: string;
    sessionId: string | null;
}> => {
    const result = await sendAndSignTransactions(transactions, displayInfo);
    await watcher.awaitCompleted(transactions[0]);
    return result;
};

export const useMysteryAddReward = (rewardType: string, rewardIdentifier: string, rewardNonce: number, rewardAmount: number, prizesNumber: number) => {
    const { account, address } = useGetAccountInfo();

    const displayInfo = {
        processingMessage: 'Processing add reward transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Reward added successfully'
    };

    const addReward = async () => {
        const contract = await getSmartContractObj();
        const numDecimals = 18;
        const gasLimit = 10000000 + (prizesNumber * 1000000);

        let interaction = contract.methodsExplicit.addReward([new U64Value(1), new U64Value(prizesNumber)]);   
        let transfer = TokenTransfer.fungibleFromAmount(rewardIdentifier, rewardAmount.toLocaleString('en-US', { useGrouping: false }), numDecimals);
        let transaction = interaction
            .withNonce(account.nonce)
            .withSingleESDTTransfer(transfer)
            .withGasLimit(gasLimit)
            .withChainID(network.chainId)
            .buildTransaction();        

        if(rewardType === 'ESDT'){
            interaction = contract.methodsExplicit.addReward([new U64Value(1), new U64Value(prizesNumber)]);   
            transfer = TokenTransfer.fungibleFromAmount(rewardIdentifier, rewardAmount.toLocaleString('en-US', { useGrouping: false }), numDecimals);
            transaction = interaction
                .withNonce(account.nonce)
                .withSingleESDTTransfer(transfer)
                .withGasLimit(gasLimit)
                .withChainID(network.chainId)
                .buildTransaction();
        }

        if(rewardType === 'NFT'){
            interaction = contract.methodsExplicit.addReward([new U64Value(0), new U64Value(prizesNumber)]);
            transfer = TokenTransfer.nonFungible(rewardIdentifier, rewardNonce);
            transaction = interaction
                .withNonce(account.nonce)
                .withValue(0)
                .withSender(new Address(address))
                .withSingleESDTNFTTransfer(transfer)
                .withGasLimit(gasLimit)
                .withChainID(network.chainId)
                .buildTransaction();
        }

        if(rewardType === 'SFT'){
            interaction = contract.methodsExplicit.addReward([new U64Value(2), new U64Value(prizesNumber)]);
            transfer = TokenTransfer.semiFungible(rewardIdentifier, rewardNonce, rewardAmount);
            transaction = interaction
                .withNonce(account.nonce)
                .withValue(0)
                .withSender(new Address(address))
                .withSingleESDTNFTTransfer(transfer)
                .withGasLimit(gasLimit)
                .withChainID(network.chainId)
                .buildTransaction();
        }
        
        const sessionId = await sendAndSignTransactionsWrapped(
            [transaction],
            displayInfo
        );
        return sessionId;
    };

    return addReward;
};
